import React, { useContext, useEffect, useState } from 'react';
import {
	CaretDownIcon,
	CaretUpIcon,
	PlusIcon,
	PrintIcon,
	ProgressDeliveredIcon,
	ProgressDispatchedIcon,
	ProgressProcessingIcon,
} from '../../../components/SVGIcons/SVGIcons';
import { checkPackDelivered, checkStatusIfDisable, dateConverter, parseTrackingStatus } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import TooltipIcon from '../../../components/TooltipIcon';
import { useNavigate } from 'react-router';
import Axios from 'axios';
import config from '../../../config';
import { itemLabelTemp } from '../../../templates/templates';
import useFetch from '../../../hooks/useFetch';
import Loading from '../../../components/Loading/Loading';
import TeamIcon from '../../../components/TeamIcon';

export default function OrderTracking(props) {
	const { order, expandItem, expandedItems, removeItem, handleModal, packages, setShowOverlay } = props?.props || {};

	const { teamState } = useContext(CurrentTeamContext);

	const { fetch } = useFetch();

	const [otherTeam, setOtherTeam] = useState({});
	const [loaded, setLoaded] = useState(false);

	let navigate = useNavigate();

	function getFilePath(file) {
		let res = Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			return url;
		});
		return res;
	}

	const prepareItemLocationData = (pack) => {
		if (!pack || !pack.locations) {
			return;
		}
		let location = pack.locations[pack.locations?.length - 1];
		let status = location ? location.status : null;
		let originTeamUuid = pack.senderTeamUuid;
		const iconClass = 'trackItemIcon';

		let originTeam = originTeamUuid === teamState.currentTeam.teamUuid ? teamState.currentTeam : otherTeam;
		switch (status) {
			case 1:
				return {
					icon: <ProgressProcessingIcon iconClass={iconClass} />,
					originTeam,
					location,
				};
			case 2:
				return {
					icon: <ProgressDispatchedIcon iconClass={iconClass} />,
					originTeam,
					location,
				};
			case 3:
				return {
					icon: <ProgressDeliveredIcon iconClass={iconClass} />,
					originTeam,
					location,
				};
		}
	};

	useEffect(() => {
		async function fetchOtherTeam() {
			let otherTeamUuid =
				order.sellerTeamUuid === teamState.currentTeam.teamUuid ? order.buyerTeamUuid : order.sellerTeamUuid;
			let otherTeam = await fetch(`${config.apiv1}/team/team.read/${otherTeamUuid}`);
			setOtherTeam(otherTeam);
			setLoaded(true);
		}

		fetchOtherTeam();
	}, [packages]);

	return (
		<div className='gridItemOrder tracking'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Tracking</h4>
				<div className='marginLeft-auto marginRight-20'>
					<TooltipIcon
						id='orderTracking'
						tooltip='Order items and their accompaniments can be tracked at the touch of a button.'
					/>
				</div>
				<div
					className='plus discIcon'
					onClick={() => {
						handleModal('orderItemNewModal', true);
						setShowOverlay(true);
					}}>
					<PlusIcon />
				</div>
			</div>
			<div className='colCont'>
				<div className='row'>
					<div className='rowItem'>
						<h4 className='subHeader'>ITEM</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>TRACKING NR.</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>PACKAGE</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>ORIGIN</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>STATUS</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'>DUE</h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'></h4>
					</div>
					<div className='rowItem'>
						<h4 className='subHeader'></h4>
					</div>
				</div>
				<div className='col'>
					{loaded ? (
						<>
							{order?.items && order?.items?.length !== 0 ? (
								order?.items?.map((item, key) => {
									let locationData = prepareItemLocationData(packages[item.packageUuid]);
									return (
										<div className='itemCont borderBottom' key={key}>
											<div className='row'>
												<div className='rowItem'>
													<p className='bodyText bold'>{item?.itemDesc || '...'}</p>
												</div>
												<div className='rowItem'>
													<p className='bodyText bold'>
														{packages[item.packageUuid]?.trackingNumber || ''}
													</p>
												</div>

												{packages[item.packageUuid] ? (
													<>
														<div className='rowItem'>
															{packages[item.packageUuid]?.packageRef}
														</div>
														<div className='rowItem'>
															<TeamIcon
																team={locationData?.originTeam}
																imgClass='smallTeamImg'
																style={{
																	height: '18px',
																	width: '18px',
																	fontSize: '0.875em',
																}}
																showHover={false}
															/>
														</div>
														<div className='rowItem'>{locationData?.icon}</div>
														<div className='rowItem'>
															Due:{' '}
															{dateConverter(
																packages[item.packageUuid]?.quotedCollectionDateTime
															)}
														</div>
														<div
															className='rowItem'
															style={{ alignItems: 'flex-end', fill: 'gray' }}>
															<PrintIcon
																iconClass='trackItemIcon pointer'
																onClick={() => {
																	itemLabelTemp(item, getFilePath, true, {
																		x: 6.5,
																		y: 3.5,
																	});
																}}
															/>
														</div>
													</>
												) : (
													<>
														<div className='rowItem'>Not assigned to package</div>
														<div className='rowItem'></div>
														<div className='rowItem'></div>
														<div className='rowItem'></div>
														<div className='rowItem'></div>
													</>
												)}
												{expandedItems && expandedItems[item?.itemUuid] ? (
													<div className='rowItem'>
														<CaretUpIcon
															iconClass='arrowExpand'
															onClick={() => {
																expandItem({
																	...expandedItems,
																	[item?.itemUuid]: !expandedItems[item?.itemUuid],
																});
															}}
														/>
													</div>
												) : (
													<div className='rowItem'>
														<CaretDownIcon
															iconClass='arrowExpand'
															onClick={() => {
																expandItem({
																	...expandedItems,
																	[item?.itemUuid]: !expandedItems[item?.itemUuid],
																});
															}}
														/>
													</div>
												)}
											</div>
											{expandedItems && (
												<>
													{expandedItems[item?.itemUuid] && (
														<div className='expandedRow'>
															<div className='flexRow'>
																{packages[item.packageUuid]?.senderTeamUuid ===
																	teamState.currentTeam.teamUuid ||
																item?.teamUuid === teamState.currentTeam.teamUuid ? (
																	<>
																		{packages[item.packageUuid]?.last?.status <
																			2 && (
																			<button
																				className='btn3-primary'
																				onClick={() => {
																					removeItem(item?.itemUuid);
																				}}>
																				Remove item
																			</button>
																		)}
																		{item.packageUuid && (
																			<button
																				className='btn3-primary'
																				onClick={() => {
																					navigate(
																						`/packages/${item.packageUuid}`
																					);
																				}}>
																				View package
																			</button>
																		)}
																	</>
																) : (
																	<></>
																)}
																{!item.packageUuid ? (
																	<button
																		className='btn3-primary'
																		onClick={() => {
																			handleModal('addToPackModal', {
																				open: true,
																				item: item,
																			});
																		}}>
																		Add to package
																	</button>
																) : (
																	<></>
																)}
															</div>
														</div>
													)}
												</>
											)}
										</div>
									);
								})
							) : (
								<>No packages to track</>
							)}
						</>
					) : (
						<Loading />
					)}
				</div>
			</div>
		</div>
	);
}

import React from 'react';
import Button from '../../../components/Button';
import { PlayCircleIcon } from '../../../components/SVGIcons/SVGIcons';
import introduction from '../../../assets/audio/dentist_introduction.mp3';

export default function HeroHeader(props) {
	const { item, isPlaying, setIsPlaying } = props || {};

	return (
		<div className='heroCarousel'>
			<div className='innerCont'>
				<div className='card'>
					{item.imgSrc && item.flip && <img src={item.imgSrc} alt='Hero image' />}
					<div className='col' style={{ backgroundColor: `${item.background ? item.background : ''}` }}>
						<h1 className='header'>{item.header}</h1>
						<p className='paragraph'>{item.paragraph}</p>
						{!isPlaying ? (
							<Button
								size='xxl'
								style='primary'
								text={
									item.header === 'Discover Procuur for Dentists'
										? 'Listen and learn'
										: 'Play video tour'
								}
								onClick={item.btnCallback}
								iconLeft={<PlayCircleIcon />}
								noFill
							/>
						) : (
							<audio controls autoPlay>
								<source src={introduction} type='audio/mpeg' />
								Your browser does not support the audio element.
							</audio>
						)}
					</div>
					{item.imgSrc && !item.flip && <img src={item.imgSrc} alt='Hero image' />}
				</div>
			</div>
		</div>
	);
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import TeamRegisterForm from '../../components/Forms/TeamRegisterForm';
import { useLocation } from 'react-router';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import useModals from '../../hooks/useModals';
import useFetch from '../../hooks/useFetch';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import usePost from '../../hooks/usePost';
import { getRolePermissions, replaceSpecialCharacters, splitUserId, toBase64 } from '../../helpers';
import Overlay from '../../components/Overlay';
import UploadModal from '../../components/Modals/UploadModal';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';

export default function TeamRegister() {
	const [slideOffset, setSlideOffset] = useState(-window.innerWidth);
	const [slideWidth, setSlideWidth] = useState(window.innerWidth);
	const [totalWidth, setTotalWidth] = useState(0);

	const [steps, setSteps] = useState(5);
	const [step, setStep] = useState(1);

	const [page, setPage] = useState(null);
	const [regTeamUuid, setRegTeamUuid] = useState(null);
	const [skippedSteps, setSkippedSteps] = useState({});
	const [isTeamRegistered, setIsTeamRegistered] = useState(false);
	const [address, setAddress] = useState({});
	const [disableNext, setDisableNext] = useState(false);
	const [disableNextBtn, setDisableNextBtn] = useState(false);
	const [isNameOK, setIsNameOK] = useState(null);
	const [showBanner, setShowBanner] = useState(true);

	const [formData, setFormData] = useState({
		memberInvites: { memberInvite_1: { inviteUuid: uuidv4() } },
		supplierInvites: { supplierInvite_1: { inviteUuid: uuidv4() } },
	});

	const [customErrors, setCustomErrors] = useState({});

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const maxSize = 10 * 1000 * 1000;

	const innerRef = useRef(null);

	const { fetch } = useFetch();

	const { teamState, teamDispatch } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	const { modalsState, handleModal, handleFadeModal } = useModals();
	let location = useLocation();

	const { data, post, reqLoading, error, setCustomUrl, setCustomMethod } = usePost(
		`${config.apiv1}/team/team.create`,
		(data) => {
			if (data.ok) {
				setIsTeamRegistered(true);
				handleFadeModal('modalFading', {
					show: true,
					isFading: false,
					message: regTeamUuid ? 'Team updated successfully' : 'Team created successfully',
				});
			} else {
				handleFadeModal('modalFading', {
					show: true,
					isFading: false,
					message: regTeamUuid
						? 'Error when updating team. Please contact an administrator'
						: 'Error when trying to create team. Please contact an administrator',
				});
			}
		},
		'teamFirstSetup'
	);

	const [settings] = useState({
		single: { windowWidth: 1024, carouselWidth: 360 },
		dual: { windowWidth: 1280, carouselWidth: 720 },
		triple: { windowWidth: 1680, carouselWidth: 1080 },
		max: { carouselWidth: 1680 },
	});

	const handleFormData = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	function handleFormSelectData(data, fieldName) {
		setFormData((formData) => ({
			...formData,
			[fieldName]: data.value,
		}));
	}

	const handleInviteData = (type, value, name, field) => {
		setCustomErrors({});
		if (name === 'externalEmail') {
			setDisableNextBtn(false);
		}
		setFormData((formData) => ({
			...formData,
			[type]: { ...formData[type], [name]: { ...formData[type][name], [field]: value } },
		}));
	};

	function handleInviteSelectData(type, value, name, field) {
		setFormData((formData) => ({
			...formData,
			[type]: { ...formData[type], [name]: { ...formData[type][name], [field]: value.value } },
		}));
	}

	const handleAddressInput = (value, name) => {
		if (value && name) {
			setAddress((address) => ({
				...address,
				[name]: value,
			}));
		} else {
			setAddress({});
		}
	};

	const handleNewFiles = async (files, name) => {
		if (!files?.length) {
			files = [files];
		}
		let base64 = await toBase64(files[0]);
		setFormData((formData) => ({
			...formData,
			[modalsState?.uploadModal?.name]: { file: files, base64: base64 },
		}));
		handleModal('uploadModal', { open: false });
	};

	function checkPage(newOffset) {
		let offset = newOffset || slideOffset;
		if (formData.isCompany) {
			if (offset === -slideWidth * 5) {
				return 'inviteMembers';
			} else if (offset === -slideWidth * 6) {
				return 'inviteSuppliers';
			}
		} else {
			if (offset === -slideWidth * 3) {
				return 'inviteMembers';
			} else if (offset === -slideWidth * 4) {
				return 'inviteSuppliers';
			}
		}
	}

	function nextPage(formId, skippedSteps) {
		function checkInvalidFields() {
			let elements = document.querySelectorAll('[isrequired]');
			if (formId) {
				let form = document.getElementById(formId);
				elements = form?.querySelectorAll('[isrequired]');
			}
			let invalid = false;

			for (let i = 0; i < elements?.length; i++) {
				if (!formData[elements[i].getAttribute('isrequired')]) {
					setCustomErrors({
						...customErrors,
						[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
					});
					elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
					invalid = true;
					return true;
				}
			}
			return invalid;
		}
		if (formId && checkInvalidFields(formId)) {
			return;
		}

		setStep((step) => step + 1);

		let newOffset = slideOffset - slideWidth;

		setPage(checkPage(newOffset));

		if (formData.isCompany) {
			let pages = 7;
			if (newOffset <= -slideWidth * pages) {
				setSteps(pages);
				setDisableNext(true);
				updateTeam({ skippedSteps });
			}
		} else {
			let pages = 5;
			if (newOffset <= -slideWidth * pages) {
				setSteps(pages);
				setDisableNext(true);
				updateTeam({ skippedSteps });
			}
		}
		setCustomUrl(`${config.apiv1}/team/team.update`);
		setCustomMethod('PATCH');

		setSlideOffset(newOffset);
	}

	function previousPage() {
		let newOffset = slideOffset + slideWidth;

		setPage(checkPage(newOffset));
		setSlideOffset(newOffset);
		setDisableNext(false);
	}

	function addMember() {
		setFormData((formData) => ({
			...formData,
			['memberInvites']: {
				...formData.memberInvites,
				[`memberInvite_${Object.keys(formData.memberInvites)?.length + 1}`]: { inviteUuid: uuidv4() },
			},
		}));
	}
	function removeMember(key) {
		let newFormData = { ...formData };
		delete newFormData.memberInvites[key];
		setFormData(() => ({
			...newFormData,
		}));
	}

	function addSupplier() {
		setFormData((formData) => ({
			...formData,
			['supplierInvites']: {
				...formData.supplierInvites,
				[`supplierInvite_${Object.keys(formData.supplierInvites)?.length + 1}`]: { inviteUuid: uuidv4() },
			},
		}));
	}

	function removeSupplier(key) {
		let newFormData = { ...formData };
		delete newFormData.supplierInvites[key];
		setFormData(() => ({
			...newFormData,
		}));
	}

	const checkNameTaken = async () => {
		function getAbbrev(teamName) {
			if (teamName && teamName.includes(' ')) {
				var parts = teamName.split(' ');
				var initials = '';
				for (var i = 0; i < parts.length && i !== 3; i++) {
					if (parts[i].length > 0 && parts[i] !== '') {
						initials += parts[i][0];
					}
				}
				return initials;
			} else {
				return teamName.slice(0, 3);
			}
		}
		let sanitizedName = replaceSpecialCharacters(formData.teamName);
		let fetchData = await fetch(
			`${config.apiv1}/team/team.read?teamName=${formData.teamName}&shopUrl=${sanitizedName.toLowerCase()}`
		);

		if (fetchData) {
			return setIsNameOK(false);
		}

		setFormData((formData) => ({
			...formData,
			shopUrl: sanitizedName.toLowerCase(),
			teamInitials: `${getAbbrev(formData.teamName.replace(/[^a-zA-Z ]/g, '')).toUpperCase()}`,
		}));

		setIsNameOK(true);
	};

	async function sendInvites(listKey, url) {
		try {
			if (!formData[listKey] || Object.keys(formData[listKey])?.length < 1) {
				return false;
			}

			let invites = [];

			for (let invite in formData[listKey]) {
				if (formData[listKey][invite]) {
					invites.push({
						recipientEmail: formData[listKey][invite].externalEmail,
						recipientName: formData[listKey][invite].externalName,
						role: formData[listKey][invite].role,
						permissions: getRolePermissions(formData[listKey][invite].role),
						customText: formData[listKey][invite].customText,
					});
				}
			}
			let emailRes = Axios({
				method: 'POST',
				url: url,
				data: {
					senderName:
						listKey === 'supplierInvites'
							? userState.currUser.name ||
							  `${userState.currUser.given_name} ${userState.currUser.family_name}`
							: formData.teamName,
					senderTeamUuid: regTeamUuid,
					invitations: invites,
				},
			});
			return await emailRes;
		} catch (err) {
			console.log(err);
		}
	}

	async function postTeam(e) {
		e.preventDefault();

		const teamUuid = new URLSearchParams(location.search).get('teamUuid');
		const data = {
			userUuid: splitUserId(userState.currUser.sub),
			preferredSuppliers: teamUuid ? [teamUuid] : [],
			companyDescription: formData.companyDescription,
			teamName: formData.teamName,
			teamInitials: formData.teamInitials,
			isCompany: formData.isCompany,
			shopUrl: formData.shopUrl,
			teamIcon: formData.teamIcon,
			companyType: formData.companyType,
		};

		let res = await post(data);

		teamDispatch({ type: 'SET_TEAM', payload: { teamUuid: res?.data?.teamUuid, reload: false } });
		teamDispatch({ type: 'RE-FETCH_TEAM' });
		setRegTeamUuid(res?.data?.teamUuid);
		nextPage();
	}

	async function updateTeam(props) {
		const { sameAsRegAddress, skippedSteps } = props || {};
		const invitedByTeamUuid = new URLSearchParams(location.search).get('teamUuid');
		let data = {};

		function appendCollectionAddress(data, formData) {
			if (formData.acceptCollect) {
				if (formData.notSameAsRegAddr) {
					data.collectionAddress = {
						addressLine1: address.collectionAddressLine1,
						addressLine2: address.collectionAddressLine2,
						addressLine3: address.collectionAddressLine3,
						city: address.collectionCity,
						postalCode: address.collectionPostalCode,
						country: address.collectionCountry,
						county: address.collectionCounty,
						collectionHours: address.collectionHours,
						collectionPoint: address.collectionPoint,
						instructions: address.collectionInstructions,
					};
				} else {
					data.collectionAddress = {
						addressLine1: address.addressLine1,
						addressLine2: address.addressLine2,
						addressLine3: address.addressLine3,
						city: address.city,
						postalCode: address.postalCode,
						country: address.country,
						county: address.county,
						collectionHours: address.collectionHours,
						collectionPoint: address.collectionPoint,
						instructions: address.collectionInstructions,
					};
				}
			}
		}

		if (formData.isCompany) {
			data = {
				teamUuid: regTeamUuid,
				registeredAddress: {
					addressUuid: uuidv4(),
					isDefault: false,
					addressType: 'registeredAddress',
					name: address.name,
					addressLine1: address.addressLine1,
					addressLine2: address.addressLine2,
					addressLine3: address.addressLine3,
					city: address.city,
					postalCode: address.postalCode,
					country: address.country,
					county: address.county,
				},
				deliveryAddress: {
					addressUuid: uuidv4(),
					isDefault: true,
					addressType: 'deliveryAddress',
					name: address.name,
					addressLine1: address.addressLine1,
					addressLine2: address.addressLine2,
					addressLine3: address.addressLine3,
					city: address.city,
					postalCode: address.postalCode,
					country: address.country,
					county: address.county,
					instructions: formData.acceptCollect ? address.collectionInstructions : address.instructions,
				},
				preferredSuppliers: invitedByTeamUuid ? [invitedByTeamUuid] : [],
				companyDescription: formData.companyDescription,
				isPublic: formData.isPublic || false,
				isCompany: formData.isCompany,
				teamIcon: formData.teamIcon,
				avatarColor: formData.avatarColor,
				teamPicture: formData.teamPicture?.base64,
				phoneNumber: formData.phoneNumber,
				businessEmail: formData.businessEmail,
				websiteUrl: formData.websiteUrl,
				currency: formData.currency,
				shopUrl: formData.shopUrl,
				tax: formData.tax,
				companyName: formData.companyName,
				companyNumber: formData.companyNumber,
				companyCountry: formData.companyCountry,
				spokenLanguage: formData.spokenLanguage || 'EN',
				mhra_registration_number: formData.mhra_registration_number,
			};
		} else {
			data = {
				teamUuid: regTeamUuid,
				teamIcon: formData.teamIcon,
				spokenLanguage: formData.spokenLanguage || 'EN',
				avatarColor: formData.avatarColor,
				preferredSuppliers: invitedByTeamUuid ? [invitedByTeamUuid] : [],
				companyDescription: formData.companyDescription,
				isSeller: formData.isSeller,
				isCompany: false,
			};
		}

		appendCollectionAddress(data, formData);

		let res = await post(data);

		if (!res.data) {
			return;
		}

		!skippedSteps?.inviteSuppliers && (await sendInvites('supplierInvites', `${config.apiv1}/email/team.invite`));
		!skippedSteps?.inviteMembers && (await sendInvites('memberInvites', `${config.apiv1}/email/member.invite`));

		teamDispatch({ type: 'SET_TEAM_DATA', payload: res.data });
	}

	useEffect(() => {
		let stepRefs = {
			memberInvite: { offsetCompany: -7000, offset: -4200 },
			supplierInvite: { offsetCompany: -8400, offset: -5600 },
		};
		if (formData.isCompany) {
			if (
				slideOffset === stepRefs.memberInvite.offsetCompany &&
				Object.keys(formData.memberInvites?.memberInvite_1)?.length < 2
			) {
				setDisableNextBtn(true);
			}
			if (
				slideOffset === stepRefs.supplierInvite.offsetCompany &&
				Object.keys(formData.supplierInvites?.supplierInvite_1)?.length < 2
			) {
				setDisableNextBtn(true);
			}
		} else {
			if (
				slideOffset === stepRefs.memberInvite.offset &&
				Object.keys(formData.memberInvites?.memberInvite_1)?.length < 2
			) {
				setDisableNextBtn(true);
			}
			if (
				slideOffset === stepRefs.supplierInvite.offset &&
				Object.keys(formData.supplierInvites?.supplierInvite_1)?.length < 2
			) {
				setDisableNextBtn(true);
			}
		}
	}, [slideOffset]);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const recalibrateSlide = () => {
			setTotalWidth(-innerRef.current.offsetWidth * steps);
			setSlideWidth(innerRef.current.offsetWidth);
			setSlideOffset(-innerRef.current.offsetWidth * step);
		};

		recalibrateSlide();
	}, [windowSize]);
	useEffect(() => {
		if (!isTeamRegistered) {
		}
	}, [isTeamRegistered]);

	return (
		<>
			<FadeOutModal
				isFading={modalsState['modalFading']?.isFading}
				message={modalsState['modalFading']?.message}
				show={modalsState['modalFading']?.show}
			/>
			{modalsState?.uploadModal?.open && (
				<>
					<Overlay
						loadOverlay={true}
						showOverlay={true}
						setShowOverlay={() => handleModal('uploadModal', { open: false })}
					/>
					<UploadModal
						handleNewFiles={handleNewFiles}
						handleModal={handleModal}
						maxFileSize={maxSize}
						postFiles={() => {
							handleModal('uploadModal', { open: false });
						}}
						files={[]}
					/>
				</>
			)}
			<TeamRegisterForm
				handleInviteSelectData={handleInviteSelectData}
				handleFormSelectData={handleFormSelectData}
				handleInviteData={handleInviteData}
				handleAddressInput={handleAddressInput}
				isTeamRegistered={isTeamRegistered}
				setSkippedSteps={setSkippedSteps}
				handleFormData={handleFormData}
				checkNameTaken={checkNameTaken}
				disableNextBtn={disableNextBtn}
				removeSupplier={removeSupplier}
				setShowBanner={setShowBanner}
				removeMember={removeMember}
				previousPage={previousPage}
				skippedSteps={skippedSteps}
				regTeamUuid={regTeamUuid}
				modalsState={modalsState}
				ref={innerRef}
				totalWidth={totalWidth}
				disableNext={disableNext}
				handleModal={handleModal}
				addSupplier={addSupplier}
				reqLoading={reqLoading}
				slideOffset={slideOffset}
				showBanner={showBanner}
				addMember={addMember}
				slideWidth={slideWidth}
				isNameOK={isNameOK}
				nextPage={nextPage}
				postTeam={postTeam}
				formData={formData}
				address={address}
				page={page}
			/>
		</>
	);
}

import React, { useEffect, useState, useRef, forwardRef } from 'react';
import OrderForm from '../Forms/OrderForm';
import StepNav from './Components/StepNav';
import Loading from '../Loading/Loading';
import TeamRegisterForm from '../Forms/TeamRegisterForm';

const MultiStepForm = forwardRef((props, ref) => {
	const { formSettings, formProps, handleSkipped, stepOverwrite, setStepOverwrite } = props || {};

	const [slideOffset, setSlideOffset] = useState(-window.innerWidth);
	const [slideWidth, setSlideWidth] = useState(window.innerWidth);
	const [totalWidth, setTotalWidth] = useState(0);

	const [currStep, setCurrStep] = useState(null);
	const [steps, setSteps] = useState({ count: 1, stepRefs: formSettings?.stepRefs });

	const [lastMousePress, setLastMousePress] = useState(null);
	const [mouseEventLoading, setMouseEventLoading] = useState(false);

	const innerRef = useRef(null);

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	function next(formId) {
		function checkInvalidFields() {
			let elements;
			if (formId) {
				let form = document.getElementById(formId);
				if (form) {
					elements = form.querySelectorAll('[isrequired]');
				} else {
					return false;
				}
				let invalid = false;

				for (let i = 0; i < elements.length; i++) {
					if (!formProps?.formData[elements[i].getAttribute('isrequired')]) {
						formProps?.setCustomErrors({
							...formProps?.customErrors,
							[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
						});
						elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
						invalid = true;
						return true;
					}
				}
				return invalid;
			} else {
				return false;
			}
		}

		if (checkInvalidFields()) {
			return;
		}

		window.scrollTo(0, 1);
		setSlideOffset(slideOffset - slideWidth);
		setSteps((steps) => ({ ...steps, count: steps.count + 1 }));
		updateCurrStep(steps.count + 1);
	}

	function prev() {
		window.scrollTo(0, 1);
		setSteps((steps) => ({ ...steps, count: steps.count - 1 }));
		setSlideOffset(slideOffset + slideWidth);
		updateCurrStep(steps.count - 1);
	}

	function skip() {
		window.scrollTo(0, 1);
		setSteps((steps) => ({ ...steps, count: steps.count + 1 }));
		setSlideOffset(slideOffset - slideWidth);
		handleSkipped(getStepRef(steps.count));
		updateCurrStep(steps.count + 1);
	}

	function getStepRef(step) {
		let refs = steps.stepRefs || {};
		for (let ref in refs) {
			if (refs[ref].stepNumber === step) {
				return { ...refs[ref], ref: ref };
			}
		}
	}

	function updateCurrStep(step) {
		if (!step) {
			step = steps?.count;
		}

		setCurrStep(getStepRef(step));
	}

	function resetSlide() {
		setSlideOffset(-slideWidth);
		setSteps({ count: 1, stepRefs: formSettings?.stepRefs });
	}

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		setSteps(() => ({ ...steps, stepRefs: formSettings?.stepRefs || steps.stepRefs }));
		updateCurrStep();
	}, [formSettings?.stepRefs]);

	useEffect(() => {
		const recalibrateSlide = () => {
			setTotalWidth(-innerRef.current.offsetWidth * Object.keys(steps?.stepRefs)?.length);
			setSlideWidth(innerRef.current.offsetWidth);
			setSlideOffset(-innerRef.current.offsetWidth * steps?.count);
		};

		recalibrateSlide();
	}, [windowSize]);

	useEffect(() => {
		if (stepOverwrite && stepOverwrite !== currStep) {
			window.scrollTo(0, 1);
			setSteps((steps) => ({ ...steps, count: stepOverwrite }));
			setSlideOffset(slideOffset + slideWidth);
			updateCurrStep(stepOverwrite);
		}
		setStepOverwrite(null);
	}, [stepOverwrite]);

	return (
		<div ref={ref} className='multiStepForm'>
			<div className='flexRow'>
				<div className='headerCol'>
					<h1 className='mainHeader'>
						{formSettings?.header?.icon}
						{formSettings?.header?.text}
					</h1>
					<p className='subHeader'>{formSettings?.subHeader?.text}</p>
				</div>
				<div className='headerCol'>
					{formSettings?.buttons?.returnBtn ? formSettings?.buttons?.returnBtn : <></>}
				</div>
			</div>
			<div
				className='sliderMask'
				style={{
					transition: '0s',
				}}>
				{formProps?.loading && formProps?.loadingMessage}
				<div
					ref={innerRef}
					className='innerCont'
					style={{
						transform: `translateX(${slideOffset + slideWidth}px)`,
						transition: 'transform 0.6s ease-in-out',
					}}>
					{formSettings?.type === 'order' && (
						<>
							<OrderForm
								setSlideOffset={setSlideOffset}
								slideOffset={slideOffset}
								totalWidth={totalWidth}
								resetSlide={resetSlide}
								slideWidth={slideWidth}
								formProps={formProps}
								steps={steps}
								skip={skip}
								next={next}
								prev={prev}
							/>
						</>
					)}
					{formSettings?.type === 'teamRegister' && (
						<>
							<TeamRegisterForm
								setSlideOffset={setSlideOffset}
								slideOffset={slideOffset}
								totalWidth={totalWidth}
								resetSlide={resetSlide}
								slideWidth={slideWidth}
								formProps={formProps}
								steps={steps}
								skip={skip}
								next={next}
								prev={prev}
							/>
						</>
					)}
				</div>
				<StepNav
					disabled={formSettings?.disabled}
					postForm={(e) => {
						formProps?.postForm(e);
						next();
					}}
					settings={currStep}
					prev={prev}
					next={next}
					skip={skip}
				/>
			</div>
		</div>
	);
});

export default MultiStepForm;

import React, { useContext, useState } from 'react';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel';
import hero_pre_1 from '../../assets/img/hero_pre_1.png';
import hero_pre_2 from '../../assets/img/hero_pre_2.png';
import hero_pre_3 from '../../assets/img/hero_pre_3.png';
import featureImg1 from '../../assets/photos/PUBLIC_pages/app_drawing-tool.jpg';
import featureImg2 from '../../assets/photos/PUBLIC_pages/app_tracking.jpg';
import featureImg3 from '../../assets/photos/PUBLIC_pages/app_manage.jpg';
import {
	AppIcon,
	CommandIcon,
	FileLinesIcon,
	ListEditIcon,
	MessageChatSquareIcon,
	ProgressIcon,
} from '../../components/SVGIcons/SVGIcons';
import FeaturesIcons from './components/FeaturesIcons';
import FeaturesList from './components/FeaturesList';
import Section from './components/Section';
import Button from '../../components/Button';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { useNavigate } from 'react-router';

export default function FrontPage() {
	let navigate = useNavigate();

	const [items, setItems] = useState([
		{
			header: 'Are you a Dental Practitioner?',
			paragraph: 'Place, view and track all your Dental Laboratory prescriptions simply on your mobile device.',
			btnText: 'Discover Procuur for Dentists',
			imgSrc: hero_pre_1,
			btnCallback: () => navigate('/features/dentists'),
		},
		{
			header: 'Are you a Dental Laboratory?',
			paragraph:
				'Receive prescriptions electronically, customize your dockets and communicate with your customers from one simple-to-use platform.',
			btnText: 'Discover Procuur for Dental Laboratories',
			imgSrc: hero_pre_2,
			flip: true,
			btnCallback: () => navigate('/features/dental_labs'),
		},
		{
			header: 'Are you a Dental Practice?',
			paragraph:
				'Gain overview of your Dental Laboratory prescriptions and give your Dental Nurses the ability to track incoming Dental Laboratory prescriptions at the touch of a button.',
			btnText: 'Discover Procuur for Practices',
			imgSrc: hero_pre_3,
			btnCallback: () => navigate('/features/dental_practices'),
		},
	]);

	const [features, setFeatures] = useState([
		{
			header: 'Digital Ordering',
			paragraph:
				'Create customized forms for your customers to complete. Invite your customers and receive orders in real-time.',
			icon: <ListEditIcon iconClass='icon' />,
		},
		{
			header: 'Order tracking',
			paragraph:
				'Give your customers tracking – they can see their orders‘ progress along with the tracking of associated files AND deliveries of staged work.',
			icon: <ProgressIcon iconClass='icon' />,
		},
		{
			header: 'Buyer to Seller Communications',
			paragraph:
				'Message with your customers and retain the conversation with the job in an easy-to-use chat app.',
			icon: <MessageChatSquareIcon iconClass='icon' />,
		},
		{
			header: 'Third-party software plug-ins',
			paragraph:
				'Send your customers invoices from within Proccur or have your orders flow through to your accounting software for that to send invoices.',
			icon: <AppIcon iconClass='icon' />,
		},
		{
			header: 'Ubiquitous – mobile, desktop and tablet',
			paragraph: 'See your jobs, chat with your customers from a mobile, tablet or desktop computer.',
			icon: <CommandIcon iconClass='icon' />,
		},
		{
			header: 'File management',
			paragraph: 'Receive or send files and photos to share with your customers.',
			icon: <FileLinesIcon iconClass='icon' />,
		},
	]);

	const [featureLists, setFeatureLists] = useState({
		custom_forms: {
			img: featureImg1,
			features: [
				'Receive your Orders on mobile, table and desktop computers',
				'Send and receive files and photos',
				'View the history of all your orders over time',
				'Give your staff the ability to process received orders',
			],
		},
		tracking: {
			img: featureImg2,
			features: [
				'Track your order',
				'Receive and send parts associated with your order over time',
				'Receive files such as your Invoice electronically',
				'View the history of all orders over time',
				'Outsource orders or parts to 3rd Parties',
			],
		},
		customers: {
			img: featureImg3,
			features: [
				'Send messages to you about the order',
				'See their orders online Track the progress of those orders online',
				'Add photos or files to their orders',
				'Get their invoice and compliance documents online',
			],
		},
	});

	const { userState } = useContext(CurrentUserContext);

	return (
		<div className='frontPage'>
			<HeroCarousel items={items} setItems={setItems} />
			<FeaturesIcons
				sectionHeader='E-Procurement made easy'
				paragraph1='Procuur joins all the disparate systems from practice management and lab management to accounting software, thus digitising prescriptions.'
				paragraph2='It’s easy and FREE* to use at its basic subscription level.'
				features={features}
				showHeader
				border
			/>
			<FeaturesList
				sectionHeader='Discover Procuur for Sellers'
				paragraph1='Procuur is a procurement platform. Our focus is on seller whom manufacture bespoke parts; known in the medi-markets sector as Custom Made Devices (CMD’s).'
				listHeader='Real-time ordering and customized forms'
				listParagraph='Create customized order forms for your customers to fill in online, sent in real-time from their mobile, tablet or desktop computer. '
				icon={<ListEditIcon iconClass='icon' />}
				features={featureLists?.custom_forms?.features}
				img={featureLists?.custom_forms?.img}
				align='left'
				showHeader
			/>
			<FeaturesList
				listHeader='Order tracking and messaging'
				listParagraph='Track the progress of your order, see inbound and outbound orders over time and manage you business around the deliveries you require.'
				icon={<ProgressIcon iconClass='icon' />}
				features={featureLists?.tracking?.features}
				img={featureLists?.tracking?.img}
				align='right'
			/>
			<FeaturesList
				listHeader='What you can now offer your customers'
				listParagraph='Your customers can send their orders to you by filling in your online form. They can do this simply from their mobile, tablet or desktop computer. It’s FREE* for them to use and you can invite them at the touch of a button.'
				icon={<MessageChatSquareIcon iconClass='icon' />}
				features={featureLists?.customers?.features}
				img={featureLists?.customers?.img}
				align='left'
				border
			/>
			<Section>
				<div className='col'>
					<h4 className='header marginBottom-20'>Getting started</h4>
					<p className='paragraph marginBottom-20'>
						You can download Procuur as a mobile app or use it from a browser (on your mobile, tablet or
						desktop).
					</p>
					<p className='paragraph'>
						At this time Procuur is not available for public registration, therefore please{' '}
						<span className='spanLinkText' onClick={() => navigate('/features/apply')}>
							Apply for Registration
						</span>
						. If you have received an invitation from your Supplier then please follow the link in that
						email.
					</p>
					<div className='col marginTop-20'>
						<Button
							size='xxl'
							text='Apply for registration'
							style='primary'
							onClick={() => navigate('/features/apply')}
						/>
						<p className='paragraph italic marginTop-40'>
							*Please note that Procuur will charge you a small fee for file storage.
						</p>
					</div>
				</div>
			</Section>
		</div>
	);
}

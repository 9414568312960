import React from 'react';
import { NavLink } from 'react-router-dom';
import { CartBuyIcon, CartIcon, ClipboardCheckIcon, HomeSimpleIcon, TeamIcon } from '../SVGIcons/SVGIcons';

export default function MobileMenu() {
	return (
		<div className='mobileMenu'>
			<NavLink to='/' className='botMenuIconCont'>
				<HomeSimpleIcon iconClass='botMenuIcon' />
			</NavLink>
			<NavLink to='/order' className='botMenuIconCont'>
				<CartBuyIcon iconClass='botMenuIcon' />
			</NavLink>
			<NavLink to='/orders' className='botMenuIconCont'>
				<ClipboardCheckIcon iconClass='botMenuIcon' />
			</NavLink>
			<NavLink to='/manage' className='botMenuIconCont'>
				<TeamIcon iconClass='botMenuIcon' style={{ marginRight: '10px' }} />
			</NavLink>
			<NavLink to='#' className='botMenuIconCont disabled'>
				<CartIcon iconClass='botMenuIcon' />
			</NavLink>
		</div>
	);
}

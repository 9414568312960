import { jsPDF } from 'jspdf';
import { cloudServiceAttachmentLabels, dateConverter, docketDataConverter, integrationDataLabels } from '../helpers';
import autoTable from 'jspdf-autotable';
import logo from '../assets/img/procuur-logo.png';
import '../assets/fonts/Nunito-Regular-normal.js';
import '../assets/fonts/Nunito-Medium-bold.js';
import '../assets/fonts/Nunito-Bold-bold.js';
import '../assets/fonts/Nunito-Bold-normal.js';

const pixelToMilimeter = 0.2645833333;
const lineLength = 8;
const margin = 2;
const maxLine = 50;

export async function packLabelTemp(pack, fileCb, format) {
	const doc = new jsPDF({
		orientation: 'l',
		unit: 'cm',
		format: [format.x, format.y],
	});

	let parsedFile = await fileCb(pack.qrCode);
	doc.setFont('Helvetica', 'bold');
	doc.setFontSize(16);
	if (pack?.address) {
		doc.text(
			`${pack?.address?.name ? `Name:  ${pack?.address?.name}\n` : ''}${
				pack?.address?.addressLine1 ? `Street:  ${pack?.address?.addressLine1}\n` : ''
			}${pack?.address?.city ? `City:  ${pack?.address?.city}\n` : ''}${
				pack?.address?.postalCode ? `Postcode:  ${pack?.address?.postalCode}\n` : ''
			}${pack?.address?.country ? `Country:  ${pack?.address?.country}\n` : ''}`,
			1,
			1.75
		);
	}
	doc.addImage(await parsedFile, 'PNG', 12.5, 0.5, 5, 5);
	doc.save(`package_label_${pack.packageUuid}.PDF`);
	return doc;
}

export async function itemLabelTemp(item, fileCb, save, format) {
	const doc = new jsPDF({
		orientation: 'l',
		unit: 'cm',
		format: [format.x, format.y],
	});

	let parsedFile = await fileCb(item.qrCode);
	doc.addImage(await parsedFile, 'PNG', format.x / 2.85, format.y / 6.5, 2, 2);

	doc.setFont('Helvetica', 'bold');
	doc.setFontSize(10);
	doc.text(`Order: ${item.orderRef}`, 2, 3);
	if (save === true) {
		doc.save(`item_label_${item.itemUuid}.PDF`);
	}
	return doc;
}

export async function itemListTemp(pack, fileCb, format) {
	const doc = new jsPDF({
		orientation: 'portrait',
		unit: 'mm',
		format: 'a4',
	});
	const pageMargin = {
		top: 74,
		bottom: 25,
		left: 15,
		right: 15,
	};

	const pageWidth = doc.internal.pageSize.width - (pageMargin.left + pageMargin.right);
	var totalPagesExp = '{total_pages_count_string}';
	const marginFooter = 15;
	const headerStart = 20;
	const bodyStart = 70;
	const lineHeight = 6;

	const largeHeaderFont = {
		fontSize: 20,
		fontStyle: 'bold',
		font: 'Nunito-Bold',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 2,
	};
	const mediumHeaderFont = {
		fontSize: 16,
		fontStyle: 'bold',
		font: 'Nunito-Bold',
		cellPadding: { top: 3, bottom: 5, left: 0, right: 0 },
		cellWidth: 'wrap',
	};
	const smallBoldFont = {
		fontSize: 12,
		fontStyle: 'bold',
		font: 'Nunito-Bold',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 1.5,
	};
	const smallSemiBoldFont = {
		fontSize: 12,
		fontStyle: 'bold',
		font: 'Nunito-Medium',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 1.5,
	};
	const smallFont = {
		fontSize: 12,
		fontStyle: 'normal',
		font: 'Nunito-Regular',
		cellPadding: { top: 1, bottom: 1, left: 0, right: 5 },
		cellWidth: pageWidth / 4,
	};
	const footerFont = {
		fontSize: 10,
		fontStyle: 'normal',
		font: 'Nunito-Regular',
		cellPadding: { top: 1, bottom: 1, left: 0, right: 5 },
	};

	async function generateHeaderFooter() {
		let parsedFile = await fileCb(pack.qrCode);
		let packRef = doc.splitTextToSize(pack.packageRef, 50);

		doc.setFillColor('#F2F2F2', 0);
		doc.rect(0, 0, doc.internal.pageSize.width, 60, 'F');
		doc.setFont(largeHeaderFont.font, 'normal');
		doc.setFontSize(largeHeaderFont.fontSize);
		doc.text(packRef, pageMargin.left, headerStart);

		doc.setFont(smallFont.font, smallFont.fontStyle);
		doc.setFontSize(smallFont.fontSize);
		doc.text('Created:', pageMargin.left, 52);

		doc.setFont(smallSemiBoldFont.font, smallSemiBoldFont.fontStyle);
		doc.setFontSize(smallSemiBoldFont.fontSize);
		doc.text(dateConverter(pack.createdAt), pageMargin.left + 30, 52);

		if (pack?.address?.name) {
			doc.setFont(smallFont.font, smallFont.fontStyle);
			doc.setFontSize(smallFont.fontSize);
			doc.text(pack?.address?.name, pageMargin.left + 74, headerStart);
		}
		if (pack?.address?.addressLine1) {
			doc.setFont(smallFont.font, smallFont.fontStyle);
			doc.setFontSize(smallFont.fontSize);
			doc.text(pack?.address?.addressLine1, pageMargin.left + 74, headerStart + lineHeight);
		}
		if (pack?.address?.city) {
			doc.setFont(smallFont.font, smallFont.fontStyle);
			doc.setFontSize(smallFont.fontSize);
			doc.text(pack?.address?.city, pageMargin.left + 74, headerStart + lineHeight * 2);
		}

		if (pack.qrCode) {
			let imgHeight = 40;
			let imgWidth = 40;

			doc.addImage(
				await parsedFile,
				'PNG',
				pageWidth + pageMargin.right - imgWidth,
				headerStart - lineHeight,
				imgWidth,
				imgHeight
			);
		}

		// Footer
		doc.setDrawColor('#D6D6D6');
		doc.line(
			0,
			doc.internal.pageSize.height - marginFooter - lineHeight - 2,
			doc.internal.pageSize.width,
			doc.internal.pageSize.height - marginFooter - lineHeight - 2
		);
		doc.setDrawColor('#000000');

		doc.addImage(logo, 'PNG', pageMargin.left, doc.internal.pageSize.height - marginFooter - lineHeight, 6, 6);

		doc.setTextColor('#A3A3A3');
		doc.setFont(footerFont.font, footerFont.fontStyle);
		doc.setFontSize(footerFont.fontSize);
		doc.text(
			'www.procuur.com',
			doc.internal.pageSize.width / 2,
			doc.internal.pageSize.height - marginFooter,
			'center'
		);

		doc.text(
			`Page ${doc.internal.getNumberOfPages()}${
				typeof doc.putTotalPages === 'function' ? ` / ${totalPagesExp}` : ''
			}`,
			doc.internal.pageSize.width - pageMargin.right * 2,
			doc.internal.pageSize.height - marginFooter,
			'left'
		);
		doc.setTextColor('#000000');

		return true;
	}

	await generateHeaderFooter();

	let rows = [];

	rows.push([
		{
			content: 'Package item list',
			styles: mediumHeaderFont,
		},
		{
			content: ``,
		},
	]);

	pack?.items?.map((item, key) => {
		rows.push([
			{
				content: 'Item description: ',
				styles: smallFont,
			},
			{
				content: `${item.itemDesc}`,
				styles: smallSemiBoldFont,
			},
		]);
		rows.push([
			{
				content: 'Note: ',
				styles: smallFont,
			},
			{
				content: `${item.note}`,
				styles: smallSemiBoldFont,
			},
		]);
		rows.push([
			{
				content: 'Item quantity: ',
				styles: smallFont,
			},
			{
				content: `${item.quantity}`,
				styles: smallSemiBoldFont,
			},
		]);
		rows.push([
			{
				content: 'Created: ',
				styles: smallFont,
			},
			{
				content: `${dateConverter(item.createdAt, 'DD/MM/YYYY hh:mm')}`,
				styles: smallSemiBoldFont,
			},
		]);
		rows.push([
			{
				content: ``,
			},
		]);
	});

	autoTable(doc, {
		startY: bodyStart,
		body: [...rows],
		tableWidth: pageWidth,
		theme: 'plain',
		margin: pageMargin,
		rowPageBreak: 'avoid',
		didDrawPage: generateHeaderFooter,
		didDrawCell: ({ row, cursor, doc, table }) => {
			// let borderLineOffset = 1;
			// const columnWidth = table.columns[1].width;
			// doc.line(
			// 	cursor.x - columnWidth,
			// 	cursor.y + row.height - borderLineOffset / 2,
			// 	cursor.x,
			// 	cursor.y + row.height - borderLineOffset / 2
			// );
			// doc.line(
			// 	cursor.x - columnWidth,
			// 	cursor.y + row.height + borderLineOffset / 2,
			// 	cursor.x,
			// 	cursor.y + row.height + borderLineOffset / 2
			// );
		},
	});

	if (typeof doc.putTotalPages === 'function') {
		doc.putTotalPages(totalPagesExp);
	}

	doc.save(`package_item_list_${pack.packageUuid}.PDF`);
	return doc;
}

export async function docketTemp(
	fields,
	files,
	teamName,
	order,
	orderFiles,
	teamData,
	address,
	buyerName,
	docketRef,
	isChangeReq,
	orderExtFiles,
	savePDF
) {
	const doc = new jsPDF({
		orientation: 'portrait',
		unit: 'mm',
		format: 'a4',
	});
	const pageMargin = {
		top: 74,
		bottom: 140,
		left: 15,
		right: 15,
	};

	const pageWidth = doc.internal.pageSize.width - (pageMargin.left + pageMargin.right);
	var totalPagesExp = '{total_pages_count_string}';
	const marginFooter = 14;
	const headerStart = 14;
	const bodyStart = 64;
	const lineHeight = 6;

	const largeHeaderFont = {
		fontSize: 20,
		fontStyle: 'bold',
		font: 'Nunito-Bold',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 2,
	};
	const mediumHeaderFont = {
		fontSize: 16,
		fontStyle: 'bold',
		font: 'Nunito-Bold',
		cellPadding: { top: 3, bottom: 5, left: 0, right: 0 },
		cellWidth: 'wrap',
	};
	const smallBoldFont = {
		fontSize: 12,
		fontStyle: 'bold',
		font: 'Nunito-Bold',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 1.5,
	};
	const smallSemiBoldFont = {
		fontSize: 10,
		fontStyle: 'bold',
		font: 'Nunito-Medium',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 1.5,
	};
	const smallSemiBoldFontHeader = {
		fontSize: 12,
		fontStyle: 'bold',
		font: 'Nunito-Medium',
		cellPadding: { top: 1, bottom: 1, left: 5, right: 5 },
		cellWidth: pageWidth / 1.5,
	};
	const smallFontHeader = {
		fontSize: 12,
		fontStyle: 'normal',
		font: 'Nunito-Regular',
		cellPadding: { top: 1, bottom: 1, left: 0, right: 5 },
		cellWidth: pageWidth / 3,
	};
	const smallFont = {
		fontSize: 10,
		fontStyle: 'normal',
		font: 'Nunito-Regular',
		cellPadding: { top: 1, bottom: 1, left: 0, right: 5 },
		cellWidth: pageWidth / 3,
	};
	const footerFont = {
		fontSize: 10,
		fontStyle: 'normal',
		font: 'Nunito-Regular',
		cellPadding: { top: 1, bottom: 1, left: 0, right: 5 },
	};

	async function generateHeaderFooter(data) {
		doc.setFillColor('#F2F2F2', 0);
		doc.rect(0, 0, doc.internal.pageSize.width, 60, 'F');
		doc.setFont(largeHeaderFont.font, 'normal');
		doc.setFontSize(largeHeaderFont.fontSize);
		doc.text(order.orderRef, pageMargin.left, headerStart);

		doc.setFont(smallFontHeader.font, smallFontHeader.fontStyle);
		doc.setFontSize(smallFontHeader.fontSize);
		doc.text('Received:', pageMargin.left, 52);

		doc.setFont(smallSemiBoldFontHeader.font, smallSemiBoldFontHeader.fontStyle);
		doc.setFontSize(smallSemiBoldFontHeader.fontSize);
		doc.text(dateConverter(order.createdAt), pageMargin.left + 30, 52);

		doc.setFont(smallBoldFont.font, smallBoldFont.fontStyle);
		doc.setFontSize(smallBoldFont.fontSize);
		doc.text(buyerName, pageMargin.left + 74, headerStart);

		if (address?.addressLine1) {
			doc.setFont(smallFontHeader.font, smallFontHeader.fontStyle);
			doc.setFontSize(smallFontHeader.fontSize);
			doc.text(address?.addressLine1, pageMargin.left + 74, headerStart + lineHeight);
		}

		if (address?.city) {
			doc.setFont(smallFontHeader.font, smallFontHeader.fontStyle);
			doc.setFontSize(smallFontHeader.fontSize);
			doc.text(address?.city, pageMargin.left + 74, headerStart + lineHeight * 2);
		}
		doc.setFont(smallFontHeader.font, smallFontHeader.fontStyle);
		doc.setFontSize(smallFontHeader.fontSize);
		doc.text(
			teamData?.teamInfo?.businessEmail ? teamData?.teamInfo?.businessEmail : '(no email address)',
			pageMargin.left + 74,
			headerStart + lineHeight * 3
		);

		doc.setFont(smallFontHeader.font, smallFontHeader.fontStyle);
		doc.setFontSize(smallFontHeader.fontSize);
		doc.text(
			teamData?.teamInfo?.phoneNumber || '(no phone number)',
			pageMargin.left + 74,
			headerStart + lineHeight * 4
		);
		if (orderFiles && orderFiles?.length !== 0) {
			for (let i = 0; i < orderFiles.length; i++) {
				if (orderFiles[i].fileAttachmentType === 2) {
					let imgHeight = 40;
					let imgWidth = 40;

					doc.addImage(
						orderFiles[i].url,
						'PNG',
						pageWidth + pageMargin.right - imgWidth,
						headerStart - lineHeight,
						imgWidth,
						imgHeight
					);
				}
			}
		}

		// Footer
		doc.setDrawColor('#D6D6D6');
		doc.line(
			0,
			doc.internal.pageSize.height - marginFooter - lineHeight - 2,
			doc.internal.pageSize.width,
			doc.internal.pageSize.height - marginFooter - lineHeight - 2
		);
		doc.setDrawColor('#000000');

		doc.addImage(logo, 'PNG', pageMargin.left, doc.internal.pageSize.height - marginFooter - lineHeight, 6, 6);

		doc.setTextColor('#A3A3A3');
		doc.setFont(footerFont.font, footerFont.fontStyle);
		doc.setFontSize(footerFont.fontSize);

		Object.keys(fields).map((key, index) => {
			let includedPrepend = 'included_field_';
			if (key.includes(includedPrepend) && fields[key]) {
				const cleanedString = `${docketDataConverter(fields[key], true)}`.replace(/\s+/g, ' ');
				doc.setFontSize(6);
				doc.text(
					doc.splitTextToSize(cleanedString, 150),
					doc.internal.pageSize.width / 2,
					doc.internal.pageSize.height - marginFooter + 5,
					'center'
				);
				doc.setFontSize(footerFont.fontSize);
			}
		});
		doc.text(
			'www.procuur.com',
			doc.internal.pageSize.width / 2,
			doc.internal.pageSize.height - marginFooter,
			'center'
		);

		doc.text(
			`Page ${doc.internal.getNumberOfPages()}${
				typeof doc.putTotalPages === 'function' ? ` / ${totalPagesExp}` : ''
			}`,
			doc.internal.pageSize.width - pageMargin.right * 2,
			doc.internal.pageSize.height - marginFooter,
			'left'
		);
		doc.setTextColor('#000000');

		return true;
	}

	await generateHeaderFooter();

	let rows = [];

	if (Object.keys(fields)?.length > 0) {
		rows.push([
			{
				content: isChangeReq ? 'Change request details' : 'Order details',
				styles: mediumHeaderFont,
			},
			{
				content: ``,
			},
		]);

		Object.keys(fields).map((key, index) => {
			let includedPrepend = 'included_field_';
			if (!key.includes(includedPrepend) && fields[key]) {
				rows.push([
					{
						content: `${integrationDataLabels('dentally')[key] || docketRef[key]?.fieldText || key}:`,
						styles: smallFont,
					},
					{
						content: `${docketDataConverter(fields[key], true)}`,
						styles: smallSemiBoldFont,
					},
				]);
			}
		});
	}

	if (Object.keys(orderExtFiles)?.length > 0) {
		rows.push([
			{
				content: 'External files',
				styles: mediumHeaderFont,
			},
			{
				content: ``,
			},
		]);

		let fieldFilter = ['service', 'extFileUuid'];
		Object.keys(orderExtFiles).map((key, index) => {
			let service = orderExtFiles[key]?.service;
			rows.push([
				{
					content: 'Service',
					styles: smallFont,
				},
				{
					content: docketDataConverter(service?.displayName, false),
					styles: smallSemiBoldFont,
				},
			]);
			Object.keys(orderExtFiles[key]).map((field, index) => {
				if (orderExtFiles[key] && !fieldFilter.includes(field) && orderExtFiles[key][field]) {
					rows.push([
						{
							content: cloudServiceAttachmentLabels(service?.serviceUuid)[field],
							styles: smallFont,
						},
						{
							content: docketDataConverter(orderExtFiles[key][field], false),
							styles: smallSemiBoldFont,
						},
					]);
				}
				return field;
			});
			return key;
		});
	}

	if (files?.length > 0) {
		doc.setDrawColor('#D6D6D6');
		doc.line(
			pageMargin.left,
			doc.internal.pageSize.height - marginFooter - 125,
			pageWidth,
			doc.internal.pageSize.height - marginFooter - 125
		);
		doc.setDrawColor('#000000');

		let imgHeight = 100;

		doc.setFont(mediumHeaderFont.font, mediumHeaderFont.fontStyle);
		doc.setFontSize(mediumHeaderFont.fontSize);
		doc.text('Images', pageMargin.left, doc.internal.pageSize.height - marginFooter - 115);
		let url = '';

		for (let i = 0; i < files.length; i++) {
			const mimetype = files[i]?.file?.type || files[i]?.type;
			if (mimetype?.toLowerCase() === 'image/png' || (mimetype?.toLowerCase() === 'image/jpeg' && i < 2)) {
				if (files[i]?.file) {
					url = window.URL?.createObjectURL(files[i]?.file);
				} else {
					url = files[i].url;
				}
				let imgDimensions = await getImgDimensions(url);
				let imgRatio = imgDimensions.width / imgDimensions.height;

				let imgMarginLeft = i > 0 ? doc.internal.pageSize.width / 2 + pageMargin.left : pageMargin.left;

				doc.addImage(
					url,
					mimetype?.split('/')[1]?.toUpperCase(),
					imgMarginLeft,
					doc.internal.pageSize.height - marginFooter - 110,
					imgHeight * imgRatio,
					imgHeight
				);
			}
		}
	} else {
		// Reduce margin that would otherwise chop off part of PDF to make room for images
		pageMargin.bottom = 50;
	}

	autoTable(doc, {
		startY: bodyStart,
		body: [...rows],
		tableWidth: pageWidth,
		theme: 'plain',
		margin: pageMargin,
		didDrawPage: (data) => {
			if (files.length < 3 && doc.internal.getNumberOfPages() > 0) {
				data.settings.margin.bottom = marginFooter + 20;
			}
			generateHeaderFooter(data);
		},
	});

	if (typeof doc.putTotalPages === 'function') {
		doc.putTotalPages(totalPagesExp);
	}

	function getImgDimensions(src) {
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.onload = () => resolve({ height: img.height, width: img.width });
			img.onerror = reject;
			img.src = src;
		});
	}

	if (savePDF === true) {
		doc.save(`docket.PDF`);
	}
	return doc.output('blob');
}

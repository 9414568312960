import React, { useContext, useState } from 'react';
import Button from '../Button';
import { CheckCircleIcon, MinusIcon, PlusIcon } from '../SVGIcons/SVGIcons';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import Table from '../Table';
import usePost from '../../hooks/usePost';
import config from '../../config';
import { ModalContext } from '../../contexts/ModalContext';

export default function SubscriptionAdvertModal({ featureName, subscriptionLevelName, close, customCloseCallback }) {
	const { teamState, teamDispatch } = useContext(CurrentTeamContext);
	let { flashFadeModal } = useContext(ModalContext);
	const { post } = usePost();
	const [showCompare, setShowCompare] = useState(false);

	return (
		<div className='innerContentModal'>
			<div className='headerSection'>
				<p className='subheading'>Upgrade</p>
				<h2>{`Upgrade to Procuur ${subscriptionLevelName} now`}</h2>
				<p>
					Start using {featureName} by upgrading to a paid subscription. Simple, transparent pricing that
					grows with you. Just £35 + VAT per month.
					<br />
					<br />
					<span className='boldSpan'>Upgrade now and get 30 days for free.</span> Cancel anytime
				</p>
				<div className='btnCont'>
					<Button size='xl' text={`Upgrade to ${subscriptionLevelName}`} style='primary' onClick={upgrade} />
					<Button
						size='xl'
						text='Decline'
						style='secondary'
						onClick={() => {
							if (customCloseCallback) {
								customCloseCallback();
							}
							close();
						}}
					/>
					<a href={`mailto:${config.emailSales}`}>
						<Button text='Contact sales' style='secondary' size='xl' />
					</a>
					<Button
						onClick={toggleComparison}
						size='xl'
						text={showCompare ? 'Hide comparisons' : 'Compare subscriptions'}
						iconLeft={showCompare ? <MinusIcon /> : <PlusIcon />}
						style='tertiary-secondary'
					/>
				</div>
				{showCompare === true ? (
					<Table
						tableRows={tableRows(upgrade, close, customCloseCallback)}
						tableColumns={[
							{ label: 'Lab Basic', key: 'labBasic' },
							{ label: 'Lab Business', key: 'labBusiness' },
							{ label: 'Lab Group', key: 'labGroup' },
						]}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);

	function toggleComparison() {
		setShowCompare((showCompare) => !showCompare);
	}

	async function upgrade() {
		try {
			let res = await post(
				{ teamUuid: teamState.currentTeam?.teamUuid },
				`${config.apiv1}/subscription/subscription.free`,
				true,
				'POST'
			);
			if (res?.status === 200) {
				teamDispatch({ type: 'RE-FETCH_TEAM' });
				flashFadeModal('Subscription successfully updated to Business!');
				close();
			} else if (res?.error && res?.error?.message?.includes('403')) {
				flashFadeModal(
					'You have already used up your free subscription time, please contact support for assistance with upgrading.'
				);
			} else {
				flashFadeModal('There was an error when trying to upgrade your subscription');
			}
		} catch (err) {
			flashFadeModal('There was an error when trying to upgrade your subscription');
		}
	}
}

var tableRows = (upgrade, close, customCloseCallback) => [
	{
		label: { text: 'Features for sellers', tooltip: null },
		type: 'subheader',
	},
	{
		label: { text: 'App integrations', tooltip: 'Test' },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Sync with Dentally', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Product management', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Job allocations', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Outsourcing', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Team management', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Messaging', tooltip: null },
		values: {
			labBasic: 'internal',
			labBusiness: 'internal + external',
			labGroup: 'internal + external',
		},
	},
	{
		label: { text: 'Order forms (dockets)', tooltip: null },
		values: {
			labBasic: '1',
			labBusiness: 'unlimited',
			labGroup: 'unlimited',
		},
	},
	{
		label: { text: 'Change request management', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: <CheckCircleIcon iconClass='checkIcon' />,
			labGroup: <CheckCircleIcon iconClass='checkIcon' />,
		},
	},
	{
		label: { text: 'Analytic insights', tooltip: null },
		values: {
			labBasic: <MinusIcon iconClass='minusIcon' />,
			labBusiness: 'basic',
			labGroup: 'advanced',
		},
	},
	{
		label: { text: 'Support', tooltip: null },
		values: {
			labBasic: 'Transactor dedicated',
			labBusiness: 'Transactor dedicated',
			labGroup: 'Transactor dedicated',
		},
	},
	{
		label: { text: 'Upgrade benefits', tooltip: null },
		type: 'subheader',
	},
	{
		label: { text: 'Seller fee', tooltip: null },
		values: { labBasic: '7%', labBusiness: '5%', labGroup: '5%' },
	},
	{
		label: { text: 'Seller value cap', tooltip: null },
		values: { labBasic: '£ 200 + VAT', labBusiness: '£ 200 + VAT', labGroup: '£ 200 + VAT' },
	},
	{
		label: { text: 'Seller gratis', tooltip: null },
		values: { labBasic: '£ 10 + VAT', labBusiness: '£ 50 + VAT', labGroup: '£ 50 + VAT' },
	},
	{
		type: 'clear',
		values: { labBasic: 'free', labBusiness: '£ 35 + VAT/mth', labGroup: '£ 99 + VAT/mth' },
	},
	{
		type: 'clear',
		values: {
			labBasic: (
				<Button
					text='Stay with basic'
					style='secondary'
					onClick={() => {
						if (customCloseCallback) {
							customCloseCallback();
						}
						close();
					}}
					size='lg'
					fill
				/>
			),
			labBusiness: <Button text='Upgrade to Business' onClick={upgrade} style='primary' size='lg' fill />,
			labGroup: <Button text='Upgrade to Business' onClick={upgrade} style='primary' size='lg' fill />,
		},
	},
	{
		type: 'clear',
		values: {
			labBusiness: (
				<a href={`mailto:${config.emailSales}`}>
					<Button text='Contact sales' style='tertiary-primary' size='lg' fill />
				</a>
			),
			labGroup: (
				<a href={`mailto:${config.emailSales}`}>
					<Button text='Contact sales' style='tertiary-primary' size='lg' fill />
				</a>
			),
		},
	},
	{
		type: 'clear',
		values: {
			// labBusiness: <Button text='Ask admin to upgrade' style='tertiary-primary' size='lg' fill />,
			// labGroup: <Button text='Ask admin to upgrade' style='tertiary-primary' size='lg' fill />,
		},
	},
];

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Axios from 'axios';
import config from '../../config';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import Loading from '../../components/Loading/Loading';
import { itemLabelTemp, itemListTemp } from '../../templates/templates';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import ProcessingModal from '../../components/Modals/ProcessingModal';
import Overlay from '../../components/Overlay';
import { ArrowLeftIcon, ChevronIconLeft, FileIcon } from '../../components/SVGIcons/SVGIcons';
import { handleResourceError } from '../../axiosErrorHandling';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import Button from '../../components/Button';
import { parseTrackingStatus } from '../../helpers';

export default function Package() {
	const location = useLocation();
	const packageUuid = new URLSearchParams(location.search).get('uuid');
	const returnUri = new URLSearchParams(location.search).get('returnUri');

	const [pack, setPack] = useState(null);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(true);
	const [delivered, setDelivered] = useState(false);
	const [modalOpen, setModalOpen] = useState({});

	let navigate = useNavigate();

	const { showModal } = useModalsGlobal();

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	function getFilePath(file) {
		let res = Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			return url;
		});
		return res;
	}

	async function registerDeliver() {
		try {
			let res = await Axios({
				url: `${config.apiv1}/location/location.create`,
				method: 'POST',
				data: {
					packageUuid: packageUuid,
					origin: pack?.locations[0]?.origin,
					status: pack?.locations[0]?.origin === 1 ? 3 : 1,
					note: 'Delivered',
				},
			});
			if (res?.data?.ok) {
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Package registered as delivered',
				});
				setPack(() => ({
					...pack,
					locations: [...pack?.locations, res?.data?.data],
				}));
				setDelivered(true);
			} else {
				setMessage('There was an error while updating the package');
			}
		} catch (err) {
			return err;
		}
	}

	async function getPackage(packageUuid) {
		try {
			setLoading(true);
			let res = await Axios({
				url: `${config.apiv1}/package/package.read/${packageUuid}`,
				method: 'GET',
			});
			if (res?.data?.data) {
				setPack(res.data.data);
				setDelivered(res.data.data?.locations[res.data.data?.locations?.length - 1]?.status === 3);
				setLoading(false);
				if (res.data.data?.items?.length === 0) {
					setModalOpen((modalOpen) => ({
						...modalOpen,
						['accessDeniedModal']: true,
					}));
				}
			} else {
				setMessage('Package was not found');
				setLoading(false);
			}
		} catch (err) {
			handleResourceError(err, navigate, showModal);
			return err;
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getPackage(packageUuid);
		}
		return () => (mounted = false);
	}, []);
	return (
		<>
			{message && <h4>{message}</h4>}
			{loading && <Loading type='circle' />}
			{pack && !loading && (
				<div className='packagePageCont'>
					{returnUri && (
						<div className='btnCont marginTop-10'>
							<Button
								onClick={() => navigate(decodeURI(returnUri))}
								text='Go back'
								iconLeft={<ArrowLeftIcon />}
							/>
						</div>
					)}
					<FadeOutModal
						show={modalOpen['modalFading']?.show}
						isFading={modalOpen['modalFading']?.isFading}
						message={modalOpen['modalFading']?.message}
					/>
					<Overlay
						loadOverlay={modalOpen['accessDeniedModal']}
						showOverlay={modalOpen['accessDeniedModal']}
					/>
					<ProcessingModal
						show={modalOpen['accessDeniedModal']}
						goToDash
						loading={false}
						message='This package has no items and cannot be registered as delivered.'
					/>
					<h2 className='marginBottom-20'>Package: {pack.packageRef || packageUuid}</h2>
					{delivered && (
						<h2 className='marginBottom-20'>
							Status: With{' '}
							{parseTrackingStatus(pack?.locations[pack?.locations?.length - 1]?.status)?.text}
						</h2>
					)}
					<h3>List of package items</h3>
					<div className='packageItemsCont'>
						{pack ? (
							pack.items.map((item, key) => {
								return (
									<div className='marginTop-20' key={key}>
										<p className='marginRight-20'>Description: {item.itemDesc}</p>
										<p>Quantity: {item.quantity}</p>
										<FileIcon
											onClick={() => {
												itemLabelTemp(item, getFilePath, true, { x: 6.5, y: 3.5 });
											}}
											iconClass='orderPageDiscIcon'
										/>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
					{!delivered && (
						<button
							className='btn3-primary marginTop-20'
							onClick={() => {
								registerDeliver();
							}}>
							Register as delivered
						</button>
					)}
					<button
						className='btn3-primary marginTop-20'
						onClick={() => {
							itemListTemp(pack, getFilePath, { x: 10, y: 10 + pack.items.length * 2 });
						}}>
						Print item list
					</button>
				</div>
			)}
		</>
	);
}

import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import config from '../../config';
import { splitUserId, toBase64 } from '../../helpers';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import TeamForm from '../../components/Forms/TeamForm';
import FadeOutModal from '../../components/Modals/FadeOutModal';

export default function TeamEdit() {
	const [collectionAddress, setCollectionAddress] = useState({});
	const [errorMessage, setErrorMessage] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [registeredAddress, setRegisteredAddress] = useState({});
	const [formData, setFormData] = useState({});

	const [modalOpen, setModalOpen] = useState({});

	let { teamState, teamDispatch } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	const millisecond = 1;

	const acceptCollectRef = useRef(null);
	const isCompanyRef = useRef(null);
	const isDeliveryAddressRef = useRef(null);
	const pageStart = useRef(null);
	const pageEnd = useRef(null);

	function reloadTeam() {
		teamDispatch({ type: 'RELOAD' });
	}

	function scrollToTop() {
		pageStart.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	}

	function scrollToBottom() {
		setTimeout(function () {
			pageEnd.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}, millisecond);
	}

	function scrollToRef(ref) {
		setTimeout(function () {
			if (ref.current) {
				ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}, millisecond);
	}

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	const handleRegisteredAddressInput = (value, name) => {
		if (value && name) {
			setRegisteredAddress((registeredAddress) => ({
				...registeredAddress,
				[name]: value,
			}));
		} else {
			setRegisteredAddress({});
		}
	};

	const handleCollectionAddressInput = (value, name) => {
		if (value && name) {
			setCollectionAddress((collectionAddress) => ({
				...collectionAddress,
				[name]: value,
			}));
		} else {
			setCollectionAddress({});
		}
	};

	function handleFormData(value, name) {
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	}

	function handleFormSelectData(data, fieldName) {
		setFormData((formData) => ({
			...formData,
			[fieldName]: data.value,
		}));
	}

	async function handleSubmit(e) {
		if (e) {
			e.preventDefault();
			e.target.disabled = true;
		}
		const data = {
			id: splitUserId(userState.currUser.sub),
			collectionAddress:
				collectionAddress && formData.acceptCollect
					? {
							collectionPoint: collectionAddress.collectionPoint,
							addressLine1: collectionAddress.addressLine1,
							addressLine2: collectionAddress.addressLine2,
							addressLine3: collectionAddress.addressLine3,
							city: collectionAddress.city,
							postalCode: collectionAddress.postalCode,
							country: collectionAddress.country,
							county: collectionAddress.county,
							collectionHours: collectionAddress.collectionHours,
							instructions: collectionAddress.instructions,
					  }
					: {},
			companyDescriptionHeader: formData.companyDescriptionHeader,
			companyDescription: formData.companyDescription,
			companyCounty: formData.companyCounty,
			teamName: formData.teamName,
			isPublic: formData.isPublic,
			acceptCollect: formData.acceptCollect,
			isCompany: formData.isCompany,
			teamIcon: formData.formProfileImg ? await toBase64(formData.formProfileImg) : undefined,
			teamUuid: formData.teamUuid,
			companyType: formData.companyType,
			teamInitials: formData.teamInitials,
			spokenLanguage: formData.spokenLanguage,
			phoneNumber: formData.phoneNumber,
			businessEmail: formData.businessEmail,
			customHoldReasons: formData.customHoldReasons,
			websiteUrl: formData.websiteUrl,
			currency: formData.currency,
			tax: formData.tax,
			registeredAddress: registeredAddress,
			companyName: formData.companyName,
			companyNumber: formData.companyNumber,
			companyCountry: formData.companyCountry,
			orderRefFormat: formData.orderRefFormat,
			mhra_registration_number: formData.mhra_registration_number,
		};

		console.log(formData);
		try {
			Axios({
				url: `${config.apiv1}/team/team.update`,
				method: 'PATCH',
				data: data,
			}).then((res) => {
				if (res.data?.error) {
					// Put file with parsing different error codes to text
					setErrorMessage('Could not create team, contact site administrator');
					scrollToTop(pageStart);
				} else {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'Team updated successfully',
					});
					teamDispatch({ type: 'RE-FETCH_TEAM' });
				}
			});
		} catch (error) {
			return err;
		}
	}

	useEffect(() => {
		let mounted = true;
		if (mounted && teamState.currentTeam) {
			if (teamState.currentTeam.teamInitials === 'ME' || teamState.currentTeam.isTeamOfOne === true) return;
			function handleFields() {
				setRegisteredAddress(teamState.currentTeam.registeredAddress);
				for (let field in teamState.currentTeam) {
					if (field === 'teamInfo') {
						handleTeamInfoFields();
					} else if (field === 'teamPreferences') {
						for (let infoField in teamState.currentTeam.teamPreferences) {
							handleFormData(teamState.currentTeam.teamPreferences[infoField], infoField);
						}
					} else {
						handleFormData(teamState.currentTeam[field], field);
					}
				}
			}

			function handleTeamInfoFields() {
				for (let infoField in teamState.currentTeam.teamInfo) {
					if (
						infoField === 'collectionAddress' &&
						Object.keys(teamState.currentTeam.teamInfo[infoField]) !== 0
					) {
						handleFormData(true, 'acceptCollect');
						setCollectionAddress(teamState.currentTeam.teamInfo[infoField]);
					}
					handleFormData(teamState.currentTeam.teamInfo[infoField], infoField);
				}
			}

			handleFields();

			setLoaded(true);
		}
		return () => (mounted = false);
	}, []);

	return (
		<>
			{loaded && (
				<>
					<FadeOutModal
						show={modalOpen['modalFading']?.show}
						isFading={modalOpen['modalFading']?.isFading}
						message={modalOpen['modalFading']?.message}
					/>
					<TeamForm
						formTitle={'Edit team'}
						registering={false}
						refs={{
							acceptCollectRef: acceptCollectRef,
							pageStart: pageStart,
							pageEnd: pageEnd,
							isCompanyRef: isCompanyRef,
							isDeliveryAddressRef: isDeliveryAddressRef,
						}}
						setFormData={setFormData}
						scrollToRef={scrollToRef}
						scrollToBottom={scrollToBottom}
						handleSubmit={handleSubmit}
						errorMessage={errorMessage}
						formData={formData}
						handleFormData={handleFormData}
						handleFormSelectData={handleFormSelectData}
						registeredAddress={registeredAddress}
						handleRegisteredAddressInput={handleRegisteredAddressInput}
						collectionAddress={collectionAddress}
						handleCollectionAddressInput={handleCollectionAddressInput}
					/>
				</>
			)}
		</>
	);
}

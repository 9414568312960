import React from 'react';
import { parseToOptions } from '../../helpers';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import TextInput from '../Inputs/TextInput';
import Button from '../Button';
import PackageChoiceItem from '../ModalListing/PackageChoiceItem';

export default function OrderItemNewForm(props) {
	const { handleData, packages, formData, handleModal, handleFormSelectData, missingOptions } = props || {};

	return (
		<>
			<TextInput
				text={'What would you like to send?'}
				name={'itemDesc'}
				callback={handleData}
				defaultValue={formData?.itemDesc}
			/>
			<TextareaInput text={'Note'} name={'note'} callback={handleData} defaultValue={formData?.note} />
			{/* <SelectInput
				options={parseToOptions(Object.values(packages), 'packages')}
				text={'For package'}
				name={'packageUuid'}
				value={formData?.packageUuid}
				callback={handleFormSelectData}
				missingOptions={missingOptions}
				reverse
			/> */}

			<div
				style={{
					backgroundColor: '#fafafa',
					borderRadius: '20px',
					padding: '30px 12px',
					width: '100%',
					boxSizing: 'border-box',
				}}>
				{!formData?.packageUuid ? (
					<div className='btnCont'>
						<Button
							text='Choose package'
							style='secondary'
							size='md'
							type='button'
							minWidth={164}
							onClick={handleModal}
						/>
					</div>
				) : (
					<>
						<PackageChoiceItem pack={packages[formData?.packageUuid]} vertical />
						<div className='btnCont'>
							<Button
								text='Change package'
								style='secondary'
								size='md'
								type='button'
								minWidth={164}
								onClick={handleModal}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}

import React, { useContext } from 'react';
import { CaretDownIcon, PenIcon } from '../../../components/SVGIcons/SVGIcons';
import { checkStatusIfDisable, dateConverter, getRolePermissions, parseProcessingStatus } from '../../../helpers';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function OrderProgress(props) {
	const {
		order,
		updatedFields,
		newOrderData,
		handleDropdown,
		handleModal,
		patchOrder,
		dropdownOpen,
		handleNewOrderData,
		isSeller,
		setShowOverlay,
	} = props?.props || {};

	const { teamState } = useContext(CurrentTeamContext);

	const rolePermissions = getRolePermissions(teamState?.teamRole);
	const shouldDisableEdit = checkStatusIfDisable(order?.orderProcessingStatus, 'editOrder', rolePermissions?.isAdmin);

	return (
		<div className='gridItemOrder progress'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Progress</h4>
				<div className='gridIconCont'>
					{isSeller && (
						<>
							{shouldDisableEdit ? (
								<div className='gridIconRegular discIcon disabledSVG'>
									<PenIcon />
								</div>
							) : (
								<div
									className='gridIconRegular discIcon'
									onClick={() => {
										handleModal('editModal', { open: true, type: 2 });
										setShowOverlay(true);
									}}>
									<PenIcon />
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<div className='row highlight'>
				<p className='text'>Status </p>
				<div
					className={`data bold ${parseProcessingStatus(order?.orderProcessingStatus).style}${
						updatedFields.orderProcessingStatus ? ' flashShadow' : ''
					}`}>
					{parseProcessingStatus(order?.orderProcessingStatus).text}
					{parseProcessingStatus(order?.orderProcessingStatus).icon}
				</div>
			</div>
			<div className='row'>
				<p className='text'>Date required </p>
				<div className={`data bold${updatedFields.requiredDateTime ? ' flashShadow' : ''}`}>
					{dateConverter(order?.requiredDateTime) || 'Not set'}
				</div>
			</div>
			<div className='row'>
				<p className='text'>Date ordered </p>
				<div className={`data bold${updatedFields.createdAt ? ' flashShadow' : ''}`}>
					{dateConverter(order?.createdAt, 'DD/MM/YYYY - hh:mm A') || 'Not set'}
				</div>
			</div>
			<div className='row'>
				<p className='text'>Expected dispatch </p>
				<div className={`data bold${updatedFields.dispatchDueDateTime ? ' flashShadow' : ''}`}>
					{dateConverter(order?.dispatchDueDateTime) || 'Not set'}
				</div>
			</div>
			{isSeller && (
				<div className='btnCont'>
					{!shouldDisableEdit && (
						<button
							className='btn3-split'
							onClick={() => {
								if (order?.orderProcessingStatus + 1 < 10) {
									patchOrder({
										orderProcessingStatus: order?.orderProcessingStatus + 1,
									});
									handleDropdown('status', false);
								}
							}}>
							Update to{' '}
							<span
								className='btnTextSpan'
								style={{
									color: `${
										parseProcessingStatus(
											order?.orderProcessingStatus + 1 < 10
												? order?.orderProcessingStatus + 1
												: order?.orderProcessingStatus
										).color
									}`,
								}}>
								{
									parseProcessingStatus(
										order?.orderProcessingStatus + 1 < 10
											? order?.orderProcessingStatus + 1
											: order?.orderProcessingStatus
									).text
								}
							</span>
							<div
								className='caretCont'
								onClick={(e) => {
									e.stopPropagation();
									handleDropdown('status', !dropdownOpen.status);
								}}>
								<CaretDownIcon iconClass='caret' />
							</div>
						</button>
					)}
					{dropdownOpen.status && (
						<div className='splitBtnDropdown'>
							<span
								id='2'
								style={{ color: `${parseProcessingStatus(2).color}` }}
								onClick={(e) => {
									handleNewOrderData(parseInt(e.target.id), 'orderProcessingStatus');
									patchOrder({
										orderProcessingStatus: parseInt(e.target.id),
									});
									handleDropdown('status', false);
								}}>
								Accepted
							</span>
							<span
								id='3'
								style={{ color: `${parseProcessingStatus(3).color}` }}
								onClick={(e) => {
									handleNewOrderData(parseInt(e.target.id), 'orderProcessingStatus');
									patchOrder({
										orderProcessingStatus: parseInt(e.target.id),
									});
									handleDropdown('status', false);
								}}>
								Processing
							</span>
							<span
								id='4'
								style={{ color: `${parseProcessingStatus(4).color}` }}
								onClick={(e) => {
									handleNewOrderData(parseInt(e.target.id), 'orderProcessingStatus');
									patchOrder({
										orderProcessingStatus: parseInt(e.target.id),
									});
									handleDropdown('status', false);
								}}>
								Completed
							</span>
							<span
								id='5'
								style={{ color: `${parseProcessingStatus(5).color}` }}
								onClick={(e) => {
									handleNewOrderData(parseInt(e.target.id), 'orderProcessingStatus');
									patchOrder({
										orderProcessingStatus: parseInt(e.target.id),
									});
									handleDropdown('status', false);
								}}>
								Dispatched
							</span>
							{rolePermissions?.isAdmin && (
								<span
									id='8'
									style={{ color: `${parseProcessingStatus(8).color}` }}
									onClick={(e) => {
										handleNewOrderData(parseInt(e.target.id), 'orderProcessingStatus');
										patchOrder({
											orderProcessingStatus: parseInt(e.target.id),
										});
										handleDropdown('status', false);
									}}>
									Void
								</span>
							)}
							<span
								id='9'
								style={{ color: `${parseProcessingStatus(9).color}` }}
								onClick={(e) => {
									handleNewOrderData(parseInt(e.target.id), 'orderProcessingStatus');
									patchOrder({
										orderProcessingStatus: parseInt(e.target.id),
									});
									handleDropdown('status', false);
								}}>
								Rejected
							</span>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

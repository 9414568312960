import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { SocketContext } from '../../App';
import { ChatIcon, ThreeDotsIcon } from '../SVGIcons/SVGIcons';
import { parseExactDate, sliceString } from '../../helpers';

export default function MessageNotification(props) {
	const {
		notification,
		setRead,
		checkHref,
		deleteNotification,
		style,
		handleDropdowns,
		dropdownsOpen,
		rightSidebarDispatch,
	} = props || {};
	const { notificationData } = notification;

	const socket = useContext(SocketContext);

	let navigate = useNavigate();

	function leaveRoom() {
		socket.emit('leave-room', notificationData.orderUuid);
	}

	function fillAndOpenRightSidebar(boxRef) {
		if (window.location.href.includes(notificationData.orderUuid)) {
			rightSidebarDispatch({ type: 'SET_TEXTBOXES_CLOSED' });
			rightSidebarDispatch({ type: 'SET_CONTENT_REFERENCE', payload: notificationData });
			rightSidebarDispatch({
				type: 'SET_EXIT_CALLBACK',
				payload: () => {
					leaveRoom();
				},
			});
			rightSidebarDispatch({ type: 'SET_CONTENT_TYPE', payload: 1 });
			rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: true });
			rightSidebarDispatch({
				type: 'SET_TEXTBOX_OPEN',
				payload: { boxRef: boxRef, boxOpen: true },
			});
		}
	}

	return (
		<>
			<div
				className={`mainRow ${notification.isRead ? 'opacity-50' : ''}`}
				onClick={() => {
					setRead(notification?.notificationUuid);
					fillAndOpenRightSidebar('chat');
					checkHref(
						`/orders/${notificationData.orderUuid}?openFocus=chat&orderRef=${notificationData?.orderRef}`
					);
					navigate(
						`/orders/${notificationData.orderUuid}?openFocus=chat&orderRef=${notificationData?.orderRef}`
					);
				}}>
				<div className={`iconCol ${style}`}>
					<ChatIcon iconClass='notificationIcon' />
				</div>
				<div className='bodyCol'>
					<div className='colRowTop'>
						<h4 className={`notificationHeader ${style}`}>{`NEW MESSAGE`}</h4>
						<h4 className='notificationDate'>{parseExactDate(notification.createdAt)}</h4>
					</div>
					<div className='colRowBottom'>
						{notificationData?.orderUuid && (
							<p className='bodyText'>
								{notification.targetTeamUuid === notificationData.buyerTeamUuid
									? `${notificationData.sellerUserRef || notification.sellerTeamName} sent a message.`
									: `${notificationData.buyerUserRef || notification.buyerTeamName} sent a message`}
							</p>
						)}
						{notificationData?.type === 'team' && (
							<p className='bodyText'>
								{notification?.senderTeamName
									? `${notificationData?.messageSenderName} from ${notification.senderTeamName} sent a message.`
									: `Unkonwn sent a message`}
							</p>
						)}
						{notificationData?.type === 'personal' && (
							<p className='bodyText'>
								{notificationData?.messageSenderName
									? `${notificationData.messageSenderName} sent a message.`
									: `Unkonwn sent a message`}
							</p>
						)}
						<ThreeDotsIcon
							iconClass='notificationSubIcon'
							onClick={(e) => {
								e.stopPropagation();
								handleDropdowns(notification?.notificationUuid);
							}}
						/>
					</div>
					<div className='colRowBottom'>
						<p className='bodyTextSmall'>{sliceString(notificationData?.messageText, 0, 30, true)}</p>
					</div>
				</div>
			</div>
			{dropdownsOpen && (
				<>
					{dropdownsOpen[notification?.notificationUuid] && (
						<div className='notificationDropdown'>
							<p
								onClick={() => {
									deleteNotification(notification);
								}}>
								Delete
							</p>
							<p
								onClick={() => {
									setRead(notification?.notificationUuid);
								}}>
								Mark as read
							</p>
						</div>
					)}
				</>
			)}
		</>
	);
}

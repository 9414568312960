import React from 'react';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import InviteTeamForm from '../Forms/InviteTeamForm';
import { TopnavCloseIcon } from '../SVGIcons/SVGIcons';
import InviteTeamFormNEW from '../Forms/InviteTeamFormNEW';
import Overlay from '../Overlay';
import ConfirmModalForm from '../Forms/ConfirmModalForm';
import InvoiceItemForm from '../Forms/InvoiceItemForm';
import ProductForm from '../Forms/ProductForm';
import PackageChoiceList from '../ModalListing/PackageChoiceList';
import PackageNewModal from '../Modals/PackageNewModal';
import PackageEditForm from '../Forms/PackageEditForm';
import UserManage from '../../containers/UserManage';
import EditAvatarForm from '../Forms/EditAvatarForm';
import PackageCreateForm from '../Forms/PackageCreateForm';
import CourierCreateForm from '../Forms/CourierCreateForm';
import ChangePackageStatusForm from '../Forms/ChangePackageStatusForm';

const Modal = () => {
	const { modals, hideModal } = useModalsGlobal();
	return (
		<>
			{modals?.length !== 0 && (
				<>
					{modals.map((modal, index) => (
						<React.Fragment key={index}>
							<div
								className='flexModal'
								style={{
									zIndex: modal.zIndex,
									position: modal?.options?.fixed === true ? 'fixed' : '',
									backgroundColor: modal?.options?.hideBackground ? 'transparent' : '',
									boxShadow: modal?.options?.hideBackground ? 'none' : '',
									maxWidth: modal?.options?.maxWidth ? `${modal?.options?.maxWidth}vw` : '',
									width: modal?.options?.fullWidth ? '100%' : '',
									padding: modal?.options?.padding !== undefined ? `${modal?.options?.padding}` : '',
									top: modal?.options?.top ? `${modal?.options?.top}px` : '',
									borderRadius: modal?.options?.borderRadius
										? `${modal?.options?.borderRadius}px`
										: '',
								}}>
								{!modal?.options?.hideCloseIcon && (
									<TopnavCloseIcon
										iconClass='closeIcon'
										onClick={() => {
											hideModal(index);
										}}
									/>
								)}
								{modal.type === 'noPermissions' && (
									<ConfirmModalForm
										hideModal={() => hideModal(index)}
										options={modal?.options}
										onlyOk={modal?.options?.onlyOk}
									/>
								)}
								{modal.type === 'manageUser' && (
									<UserManage hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'inviteTeam' && (
									<InviteTeamFormNEW hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'addInvoiceItem' && (
									<InvoiceItemForm hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'editInvoiceItem' && (
									<InvoiceItemForm hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'changePackageStatus' && (
									<ChangePackageStatusForm
										hideModal={() => hideModal(index)}
										options={modal?.options}
									/>
								)}
								{modal.type === 'editPackage' && (
									<PackageEditForm hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'createPackage' && (
									<PackageCreateForm hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'createCourier' && (
									<CourierCreateForm hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'product' && (
									<ProductForm hideModal={() => hideModal(index)} options={modal?.options} />
								)}
								{modal.type === 'packageChoiceList' && (
									<PackageChoiceList options={modal?.options} hideModal={() => hideModal(index)} />
								)}
								{modal.type === 'packageNewModal' && (
									<PackageNewModal options={modal?.options} hideModal={() => hideModal(index)} />
								)}
								{modal.type === 'confirmChoice' && (
									<ConfirmModalForm options={modal?.options} hideModal={() => hideModal(index)} />
								)}
								{modal.type === 'editAvatar' && (
									<EditAvatarForm options={modal?.options} hideModal={() => hideModal(index)} />
								)}
							</div>
							<Overlay
								loadOverlay={true}
								showOverlay={true}
								setShowOverlay={() => hideModal(modals?.length)}
								zIndex={99 + index * 2}
							/>
						</React.Fragment>
					))}
				</>
			)}
		</>
	);
};

export default Modal;

import React, { useEffect, useState } from 'react';
import TeamIcon from '../../../components/TeamIcon';
import ChatTypeTabs from './ChatTypeTabs';
import Loading from '../../../components/Loading/Loading';
import DisplayCount from './DisplayCount';
import Button from '../../../components/Button';
import { ChevronDownIcon, PenIcon, UserIcon } from '../../../components/SVGIcons/SVGIcons';
import useWindowSize from '../../../hooks/useWindowSize';
import { breakpoints, sliceString } from '../../../helpers';
import AvatarImg from '../../../components/AvatarImg';
import SearchInput from '../../../components/Inputs/SearchInput';

export default function ChatSelectColumn({
	rooms,
	updateDebounceSearch,
	handleSelectRoom,
	setSearchParam,
	selectedRoom,
	selectedTab,
	chatRoomSearch,
	setSelectedTab,
	handleRoomSkip,
	totalRooms,
	roomLimit,
	roomsSkip,
	loaded,
	tabs,
}) {
	const { width } = useWindowSize();

	return (
		<div className='chatSelectColumnCont'>
			<ChatTypeTabs
				tabs={tabs}
				setSelectedTab={setSelectedTab}
				selectedTab={selectedTab}
				setSearchParam={setSearchParam}
			/>
			{tabs.get(selectedTab)?.type === 'order' ? (
				<SearchInput defaultValue={chatRoomSearch} callback={updateDebounceSearch} noFormCont />
			) : (
				<></>
			)}
			<div className='listCont'>
				{loaded && rooms ? (
					<>
						{[...rooms.values()]
							?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
							?.map((room, key) => {
								if (room?.type === tabs.get(selectedTab)?.type) {
									return (
										<div
											className={`chatItem${selectedRoom == room.roomUuid ? ' selected' : ''}${
												room.unreadMessageCount > 0 ? ' unread' : ''
											}`}
											key={room.roomUuid}
											onClick={() => handleSelectRoom(room.roomUuid)}>
											{room.type === 'team' || room.type === 'order' ? (
												<>
													{room.otherParty?.teamInitials !== 'ME' ? (
														<TeamIcon
															team={room.otherParty}
															showHover={false}
															imgClass='defaultTeamImg'
														/>
													) : (
														<div className='userPlaceholderIcon'>
															<UserIcon />
														</div>
													)}
												</>
											) : (
												<></>
											)}
											{room.type === 'personal' ? (
												<>
													{room.otherUser?.avatar ? (
														<AvatarImg user={room.otherUser} />
													) : (
														<div className='userPlaceholderIcon'>
															<UserIcon />
														</div>
													)}
												</>
											) : (
												<></>
											)}
											<div>
												<h4>{room.chatTitle}</h4>
												{room.sellerHint?.hint ? (
													<h5>{sliceString(room.sellerHint.hint, 0, 30, true)}</h5>
												) : room.buyerHint?.hint ? (
													<h5>{sliceString(room.buyerHint.hint, 0, 30, true)}</h5>
												) : (
													<></>
												)}
											</div>
											<div className='rightGroup'>
												{selectedRoom !== room.roomUuid &&
												localStorage.getItem(`storedRoomMessage.${room.roomUuid}`) ? (
													<PenIcon iconClass='unsentTextIcon' />
												) : (
													<></>
												)}
												<DisplayCount count={room.unreadMessageCount} />
											</div>
										</div>
									);
								} else {
									return <React.Fragment key={room.roomUuid}></React.Fragment>;
								}
							})}
						{totalRooms.get(tabs.get(selectedTab)?.type) >
							roomLimit + roomsSkip[tabs.get(selectedTab)?.type]?.skip && (
							<div className='marginTop-auto marginLeft-auto marginRight-auto'>
								<Button
									text={width > breakpoints.tablet.max ? 'Show more' : ''}
									iconLeft={<ChevronDownIcon />}
									minWidth={width > breakpoints.tablet.max ? null : 40}
									maxWidth={width > breakpoints.tablet.max ? null : 40}
									style='tertiary-primary'
									onClick={() => {
										handleRoomSkip(tabs.get(selectedTab)?.type, false);
									}}
								/>
							</div>
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</div>
	);
}

import React, { useContext } from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextareaInput from '../Inputs/TextareaInput';
import holdReasonOptions from '../Options/holdReasonOptions';
import CheckboxInput from '../Inputs/CheckboxInput';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { parseToOptions } from '../../helpers';

export default function HoldOrderForm(props) {
	const { handleData, formData, handleFormSelectData } = props || {};

	const { teamState } = useContext(CurrentTeamContext);

	const holdReasons = [
		{ value: 'buyer_fitting', text: 'Buyer fitting' },
		{ value: 'buyer_testing', text: 'Buyer testing' },
		{ value: 'buyer_decision', text: 'Buyer decision' },
		{ value: 'buyer_request', text: 'Buyer request' },
		{ value: 'seller_awaiting_parts', text: 'Seller awaiting parts' },
		{ value: 'buyer_other', text: 'Buyer other' },
		{ value: 'seller_other', text: 'Seller other' },
	];

	return (
		<>
			<TextareaInput
				text={'Description'}
				name={'holdDescription'}
				callback={handleData}
				defaultValue={formData?.holdDescription}
				tooltip='Describe what the other party needs to do to further the ordering process and release the hold status'
			/>
			<CheckboxInput
				text={'Private hold'}
				name={'private'}
				checked={formData?.private}
				callback={(value, name) => {
					handleData(value, name);
					if (formData.confirmationRequired) {
						handleData(false, 'confirmationRequired');
					}
				}}
				tooltip={'If checked, the hold will only be visible to your team'}
			/>
			{!formData?.private ? (
				<CheckboxInput
					text={'Request confirmation'}
					name={'confirmationRequired'}
					checked={formData?.confirmationRequired}
					callback={handleData}
					tooltip={
						'If checked, the other party will be prompted to mark the task as done, which you will be notified about.'
					}
				/>
			) : (
				<></>
			)}
			<SelectInput
				options={
					teamState?.currentTeam?.customHoldReasons
						? [
								...parseToOptions(
									Object.values(teamState.currentTeam.customHoldReasons),
									'holdReasons'
								),
								...parseToOptions(holdReasons, 'holdReasons'),
						  ]
						: [...parseToOptions(holdReasons, 'holdReasons')]
				}
				text={'Reason'}
				name={'reason'}
				value={formData?.reason}
				callback={handleFormSelectData}
			/>
		</>
	);
}

import React, { createContext, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

const ModalPortalContext = createContext();

export const ModalPortalContextProvider = ({ children }) => {
	const [modalPortalConfig, setModalPortalConfig] = useState(null);

	const openModalPortal = (content, container) => {
		setModalPortalConfig({ content, container });
	};

	const closeModalPortal = () => {
		setModalPortalConfig(null);
	};

	return (
		<ModalPortalContext.Provider value={{ openModalPortal, closeModalPortal }}>
			{children}
			{modalPortalConfig &&
				createPortal(
					<div className='modalPortalBackdrop'>
						<div className='modalPortal'>{modalPortalConfig.content}</div>
					</div>,
					modalPortalConfig.container || document.body
				)}
		</ModalPortalContext.Provider>
	);
};

export const useModalPortal = () => useContext(ModalPortalContext);

import React, { useContext, useEffect } from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DateInput';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useForms } from '../../hooks/useForms';
import * as yup from 'yup';
import Button from '../Button';

export default function PackageEditForm({ options, hideModal }) {
	const { callback, submitUrl, method, data } = options || {};
	const formValidationSchema = yup.object().shape({
		quotedCollectionDateTime: yup.date(),
		quotedDeliveryDateTime: yup.date(),
	});

	const { teamState } = useContext(CurrentTeamContext);

	let packageEditMap = (formData) => {
		return {
			quotedCollectionDateTime: formData.quotedCollectionDateTime,
			quotedDeliveryDateTime: formData.quotedDeliveryDateTime,
			trackingNumber: formData.trackingNumber,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'packageEdit',
		formValidationSchema,
		packageEditMap,
		submitUrl,
		method
	);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `Package updated successfully`, callback);
		if (success === true) {
			hideModal();
		}
	};

	useEffect(() => {
		if (data) {
			updateForm({ ...data });
		}
	}, []);

	return (
		<>
			<form id='packageEdit'>
				<h2>Edit package</h2>
				<DateInput
					text={'Pickup'}
					name={'quotedCollectionDateTime'}
					callback={handleFormData}
					defaultValue={formData?.quotedCollectionDateTime || data?.quotedCollectionDateTime}
					dateFormat='Pp'
					min={new Date()}
					timeSelect
					required
				/>
				<DateInput
					text={'Delivery'}
					name={'quotedDeliveryDateTime'}
					callback={handleFormData}
					defaultValue={formData?.quotedDeliveryDateTime || data?.quotedDeliveryDateTime}
					dateFormat='Pp'
					min={new Date()}
					timeSelect
				/>
				<TextInput
					text={'Tracking number'}
					name={'trackingNumber'}
					value={formData?.trackingNumber}
					callback={handleFormData}
					controlled
				/>
			</form>
			<div className='btnCont'>
				<Button
					size='md'
					style='primary'
					text='Save'
					onClick={handleSubmit}
					loading={reqLoading}
					minWidth={135}
				/>
			</div>
		</>
	);
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Axios from 'axios';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import NotificationSwitch from './NotificationSwitch';
import ListingPagination from '../Pagination/ListingPagination';
import config from '../../config';
import { SocketContext } from '../../App';
import Loading from '../Loading/Loading';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';
import { ArrowNarrowLeftIcon, ArrowPrevLeftIcon, CheckDoubleIcon, SlidersIcon } from '../SVGIcons/SVGIcons';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import usePost from '../../hooks/usePost';
import NotificationSettings from './NotificationSettings';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { notificationDefaults, splitUserId } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import { ModalContext } from '../../contexts/ModalContext';
import EmptyState from '../EmptyState/EmptyState';
import OneSignal from 'react-onesignal';

export default function Notifications(props) {
	const { open } = props || {};

	const innerRef = useRef(null);

	const [loaded, setLoaded] = useState(false);
	const [reload, setReload] = useState(false);
	const [pages, setPages] = useState(0);
	const [skip, setSkip] = useState(0);
	const [message, setMessage] = useState(null);
	const [notifications, setNotifications] = useState([]);
	const [dropdownsOpen, setDropdownsOpen] = useState({});
	const [notificationSettings, setNotificationSettings] = useState({});

	const [slideOffset, setSlideOffset] = useState(-window.innerWidth);
	const [slideWidth, setSlideWidth] = useState(window.innerWidth);
	const [totalWidth, setTotalWidth] = useState(0);

	const [isSubscribed, setIsSubscribed] = useState(false);

	const [currStep, setCurrStep] = useState(null);
	const [steps, setSteps] = useState({
		count: 1,
		stepRefs: {
			listing: { stepNumber: 1, prev: false, next: true },
			settings: { stepNumber: 2, prev: true, next: false },
		},
	});

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const { post } = usePost();

	const { fetch } = useFetch();

	const topOfPage = useRef(null);

	const { teamState } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);
	const { rightSidebarDispatch } = useContext(RightSidebarContext);
	let { notificationsState, notificationsDispatch } = useContext(NotificationContext);
	const socket = useContext(SocketContext);

	const { showModal } = useModalsGlobal();
	const { flashFadeModal } = useContext(ModalContext);

	const params = useParams();
	let location = useLocation();

	function handlePagination(data) {
		let selected = data.selected;
		let newSkip = Math.ceil(selected * 20);
		setSkip(newSkip);
		notificationsDispatch({ type: 'SET_PAGE', payload: selected });
		setReload(true);
		topOfPage?.current?.scrollIntoView({ behavior: 'smooth' });
	}

	const rePromptUser = () => {
		localStorage.removeItem('onesignal-notification-prompt');
		OneSignal.Slidedown.promptPush();
	};

	const handleDropdowns = (notificationUuid) => {
		if (notificationUuid) {
			setDropdownsOpen({
				[notificationUuid]: !dropdownsOpen[notificationUuid],
			});
		} else {
			setDropdownsOpen({});
		}
	};

	function next() {
		setSlideOffset(slideOffset - slideWidth);
		setSteps((steps) => ({ ...steps, count: steps.count + 1 }));
	}

	function prev() {
		setSteps((steps) => ({ ...steps, count: steps.count - 1 }));
		setSlideOffset(slideOffset + slideWidth);
	}

	async function deleteNotification(notification, cb) {
		try {
			const res = await Axios({
				url: `${config.apiv1}/notification/notification.delete/${notification.notificationUuid}`,
				method: 'DELETE',
			});

			if (res?.data) {
				const delay = 400;
				setTimeout(() => {
					notificationsDispatch({ type: 'ACK_NOTIFICATION', payload: notification });
					setReload(true);
				}, delay);
			}
		} catch (err) {
			return err;
		}
	}

	async function setRead(notificationUuid) {
		try {
			const res = await Axios({
				url: `${config.apiv1}/notification/notification.update/${notificationUuid}`,
				method: 'PATCH',
				data: {
					isRead: true,
				},
			});

			if (res.data) {
				notificationsDispatch({ type: 'SET_READ', payload: notificationUuid });
				setDropdownsOpen({});
				setReload(true);
			}
		} catch (err) {
			return err;
		}
	}

	function cleanerFunction() {
		setLoaded(false);
		document.removeEventListener('click', handleDropdowns);
	}

	function checkHref(target) {
		if (window.location.href === `${config.host2}${target}`) {
			window.location.reload();
		}
	}

	async function markAllAsRead() {
		let body = {
			isRead: true,
		};

		notificationsDispatch({ type: 'SET_ALL_NOTIFICATIONS_READ' });

		let res = await post(
			body,
			`${config.apiv1}/notification/notification.update/team/${teamState?.currentTeam?.teamUuid}`,
			false,
			'PATCH'
		);
	}

	async function updateSetting(name, value) {
		setNotificationSettings((notificationSettings) => ({
			...notificationSettings,
			[name]: value,
		}));
		if (notificationSettings?.notificationSettingsUuid) {
			await post(
				{ [name]: value },
				`${config.apiv1}/notification/notification-settings.update/${notificationSettings.notificationSettingsUuid}`,
				false,
				'PATCH'
			);
		} else {
			await post(
				{
					teamUuid: teamState?.currentTeam?.teamUuid,
					userUuid: splitUserId(userState?.currUser?.sub),
					[name]: value,
				},
				`${config.apiv1}/notification/notification-settings.create`,
				false,
				'POST'
			);
		}
		flashFadeModal(`Settings updated`);
	}

	async function resetSettingDefaults() {
		let role = teamState.teamRole;
		let roleDefaultSettings = role ? notificationDefaults[role] : notificationDefaults.administrator;

		setNotificationSettings((notificationSettings) => ({
			...notificationSettings,
			...roleDefaultSettings,
		}));
		if (notificationSettings?.notificationSettingsUuid) {
			await post(
				{ ...roleDefaultSettings },
				`${config.apiv1}/notification/notification-settings.update/${notificationSettings.notificationSettingsUuid}`,
				false,
				'PATCH'
			);
		} else {
			let res = await post(
				{
					teamUuid: teamState?.currentTeam?.teamUuid,
					userUuid: splitUserId(userState?.currUser?.sub),
					...roleDefaultSettings,
				},
				`${config.apiv1}/notification/notification-settings.create`,
				false,
				'POST'
			);
			if (res.data) {
				setNotificationSettings((notificationSettings) => ({
					...notificationSettings,
					...res.data,
				}));
			}
		}
		flashFadeModal(`Settings updated`);
	}

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const recalibrateSlide = () => {
			setTotalWidth(-innerRef?.current?.offsetWidth * Object.keys(steps?.stepRefs)?.length);
			setSlideWidth(innerRef?.current?.offsetWidth);
			setSlideOffset(-innerRef?.current?.offsetWidth * steps?.count);
		};

		recalibrateSlide();
	}, [windowSize]);

	useEffect(async () => {
		let settings = await fetch(
			`${config.apiv1}/notification/notification-settings.read/${splitUserId(
				userState?.currUser?.sub
			)}?teamUuid=${teamState?.currentTeam?.teamUuid}`
		);
		if (settings.length !== 0) {
			setNotificationSettings(settings[0]);
		}
	}, []);

	useEffect(() => {
		notificationsDispatch({ type: 'SET_PAGE', payload: 0 });
	}, [location, open]);

	useEffect(() => {
		setPages(Math.ceil(notificationsState.totalNotifications / 20));
		setNotifications(() => [...notificationsState.notifications]);
	}, [notificationsState, open]);

	useEffect(() => {
		const checkSubscription = async () => {
			try {
				const subscribed = await OneSignal.User.PushSubscription.id;

				setIsSubscribed(subscribed);
			} catch (err) {
				console.log(err);
			}
		};

		checkSubscription();
	}, []);

	// useEffect(() => {
	// 	let mounted = true;
	// 	if (mounted) {
	// 		if (!loaded || reload) {
	// 			try {
	// 				Axios({
	// 					url: `${config.apiv1}/notification/notifications.read/${teamState.currentTeam.teamUuid}?skip=${
	// 						skip || ''
	// 					}&limit=${20}&type=${params.notificationType || ''}`,
	// 					method: 'GET',
	// 				}).then((res) => {
	// 					if (res?.data?.data) {
	// 						setPages(Math.ceil(res.data.totalDocuments / 20));
	// 						res.data.data.sort((a, b) => {
	// 							if (a.isRead && !b.isRead) {
	// 								return 1;
	// 							}
	// 							if (!a.isRead && b.isRead) {
	// 								return -1;
	// 							}
	// 							return 0;
	// 						});
	// 						setNotifications(res.data.data);
	// 						console.log(notifications);
	// 						setReload(false);
	// 						setLoaded(true);
	// 					} else {
	// 						setLoaded(true);
	// 						setMessage('You have no notifications');
	// 					}
	// 				});
	// 			} catch (err) {
	// 				setMessage('Error when fetching notifications');
	// 				// if (err.response.status === 401) {
	// 				// 	window.location.reload();
	// 				// }
	// 			}
	// 		}
	// 	}
	// 	document.addEventListener('click', handleDropdowns);
	// 	return () => {
	// 		mounted = false;
	// 		cleanerFunction();
	// 	};
	// }, [skip, reload, open]);

	useEffect(() => {
		if (!open) {
			cleanerFunction();
		} else {
			setLoaded(true);
		}
	}, [open]);

	return (
		<>
			{steps?.count === 2 ? (
				<div className='rightSidebarTopRow'>
					<ArrowNarrowLeftIcon iconClass='closeIcon' onClick={prev} />
				</div>
			) : (
				<></>
			)}
			{open ? (
				<div className='notificationsCont'>
					<div
						className='innerCont'
						ref={innerRef}
						style={{
							transform: `translateX(${slideOffset + slideWidth}px)`,
							transition: 'transform 0.5s ease-in-out',
						}}>
						<div
							className='notificationsStep'
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							{loaded ? (
								<div className='notificationsList'>
									<div ref={topOfPage}></div>
									<div className='notificationsListHeader'>
										<h4>Notifications</h4>{' '}
										<div className='rowIcons'>
											<CheckDoubleIcon
												iconClass='headerIcon icon'
												onClick={() => {
													showModal('confirmChoice', {
														callback: () => markAllAsRead(),
														fixed: true,
														header: 'Are you sure you want to set all of your notifications as read?',
														zIndex: 105,
													});
												}}
											/>
											<SlidersIcon iconClass='headerIcon icon' onClick={next} />
										</div>
									</div>
									{message && <h3 className='marginTop-40'>{message}</h3>}
									{notifications?.length > 0 ? (
										notifications.map((notification, key) => {
											return (
												<React.Fragment key={key}>
													<NotificationSwitch
														rightSidebarDispatch={rightSidebarDispatch}
														deleteNotification={deleteNotification}
														handleDropdowns={handleDropdowns}
														dropdownsOpen={dropdownsOpen}
														notification={notification}
														checkHref={checkHref}
														setRead={setRead}
													/>
												</React.Fragment>
											);
										})
									) : (
										<EmptyState body='All caught up!' header='You have no notifications' />
									)}
								</div>
							) : (
								<Loading type='circle' />
							)}
							{open && pages > 0 && <ListingPagination handleClick={handlePagination} pages={pages} />}
						</div>
						<div
							className='notificationsStep'
							style={{
								minWidth: `${slideWidth}px`,
							}}>
							<NotificationSettings
								stepWidth={windowSize?.width > 680 ? 380 : 320}
								updateSetting={updateSetting}
								resetSettingDefaults={resetSettingDefaults}
								formData={notificationSettings}
								isSubscribed={isSubscribed}
								rePromptUser={rePromptUser}
							/>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}

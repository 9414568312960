import React from 'react';
import ReactPaginate from 'react-paginate';
import { ArrowNextRightIcon, ArrowPrevLeftIcon } from '../SVGIcons/SVGIcons';
import useWindowSize from '../../hooks/useWindowSize';
import config from '../../config';

export default function ListingPagination(props) {
	const { pages, handleClick, uri } = props || {};

	const { width } = useWindowSize();

	const buildHref = (page) => {
		return `${config.host}${uri}${uri?.includes('?') ? '&' : '?'}page=${page}`;
	};

	return (
		<ReactPaginate
			previousLabel={<ArrowPrevLeftIcon iconClass='chevronIconGray' />}
			nextLabel={<ArrowNextRightIcon iconClass='chevronIconGray' />}
			breakLabel={'...'}
			breakClassName={'paginationBreak'}
			pageCount={pages}
			marginPagesDisplayed={2}
			pageRangeDisplayed={width < 648 ? 2 : 5}
			onPageChange={handleClick}
			containerClassName={'paginationContainer'}
			pageClassName={'paginationPage'}
			pageLinkClassName={'paginationLink'}
			activeClassName={'paginationActive'}
			previousClassName={'paginationPrevious'}
			nextClassName={'paginationNext'}
			disabledClassName={'disabledDiv'}
			hrefBuilder={buildHref}
		/>
	);
}

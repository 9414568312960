import React, { useEffect, useState, useContext } from 'react';
import Loading from '../../Loading/Loading';
import Button from '../../Button';
import { ArrowNarrowRightIcon, ProgressHoldIcon } from '../../SVGIcons/SVGIcons';
import { dateConverter, parseProcessingStatus, parseTrackingStatus, timestampConverter } from '../../../helpers';
import { useNavigate } from 'react-router';
import EmptyState from '../../EmptyState/EmptyState';
import useWindowSize from '../../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function PackageListItems(props) {
	const { listItems, loading } = props || {};
	const [lastMousePress, setLastMousePress] = useState(null);
	const [newMousePress, setNewMousePress] = useState(null);
	const [loadingEnrichment, setLoadingEnrichment] = useState(false);
	const [enrichedListItems, setEnrichedListItems] = useState([]);

	let navigate = useNavigate();

	const { teamState } = useContext(CurrentTeamContext);

	const { width } = useWindowSize();

	function onTouchStart(e) {
		setLastMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchMove(e) {
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchEnd(e, item) {
		if (newMousePress?.x === lastMousePress?.x && newMousePress?.y === lastMousePress?.y) {
			navigate(`/packages/${item.packageUuid}`);
		}
	}

	useEffect(() => {
		if (listItems) {
			let members = teamState?.currentTeam?.members?.filter((member) => member.firstname && member.lastname);
			let enrichedListItems = [...listItems];
			enrichedListItems.forEach((item) => {
				let matchingMember = members.filter((member) => member.userUuid === item.userUuid);
				item.user = matchingMember[0];
			});
			setEnrichedListItems(enrichedListItems);
		}

		setLoadingEnrichment(false);
	}, [listItems]);

	return (
		<div className='listOuterCont'>
			<div className='list'>
				<div className='listHeader'>
					<h4>Recipient</h4>
					<h4>Courier</h4>
					<h4>Items</h4>
					<h4>Location</h4>
					<h4>Date</h4>
					{width > 1028 && <h4></h4>}
				</div>
				{!loading ? (
					<>
						{listItems && listItems?.length !== 0 ? (
							<>
								{listItems.map((item, key) => {
									return (
										<div
											key={key}
											className='item'
											onTouchStart={onTouchStart}
											onTouchMove={onTouchMove}
											onTouchEnd={(e) => onTouchEnd(e, item)}
											onClick={() => {
												width < 1028 ? navigate(`/packages/${item.packageUuid}`) : null;
											}}>
											<p className='data'>{item.recipient || ''}</p>
											<p className='data'>{item.courier ? item.courier?.companyName : ''}</p>
											<p className='data'>{item.items?.length ? item.items?.length : 0}</p>
											<p className='data'>
												{item?.locations
													? `${
															parseTrackingStatus(
																item?.locations[item?.locations?.length - 1]?.status
															)?.text
													  }`
													: 'Unknown'}
												{teamState?.currentTeam?.teamUuid === item.senderTeamUuid
													? ' (Outgoing)'
													: ' (Incoming)'}
											</p>
											<p className='data'>
												{`${dateConverter(
													item.quotedCollectionDateTime
												)} - ${timestampConverter(item.quotedCollectionDateTime)}`}
											</p>
											{width > 1028 && (
												<p className='data'>
													<Button
														text='See details'
														size='md'
														style='tertiary-secondary'
														iconRight={<ArrowNarrowRightIcon />}
														onClick={() => {
															navigate(`/packages/${item.packageUuid}`);
														}}
													/>
												</p>
											)}
										</div>
									);
								})}
							</>
						) : (
							<EmptyState body='No packages matched your search filters' header='No packages found' />
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</div>
	);
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import { areDifferentDays, clarifyDate, mapMessageFields, splitUserId, timestampConverter } from '../../helpers';
import Axios from 'axios';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import Loading from '../Loading/Loading';
import { PaperPlaneIcon } from '../SVGIcons/SVGIcons';
import { Link } from 'react-router-dom';
import { SocketContext } from '../../App';
import { RightSidebarContext } from '../../contexts/RightSidebarContext';

export default function Chat(props) {
	const { order, open } = props || {};

	const [inputFilled, setInputFilled] = useState(false);
	const [room, setRoom] = useState({ messages: [] });
	const [loaded, setLoaded] = useState(false);
	const [chatStarted, setChatStarted] = useState(false);
	const [socketJoined, setSocketJoined] = useState(false);

	const divEnd = useRef();
	const message = useRef();
	const chatBox = useRef();

	let { notificationsDispatch } = useContext(NotificationContext);
	let { rightSidebarDispatch } = useContext(RightSidebarContext);
	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);

	let socket = useContext(SocketContext);

	const handleNewMessage = () => {
		const chat = chatBox.current;
		chat?.childNodes[chat?.childNodes?.length - 1]?.scrollIntoView({
			block: 'end',
		});
	};

	const handleScroll = (e) => {
		if (!e) {
			patchChatToRead(room.roomUuid);
			notificationsDispatch({ type: 'SET_ALL_MESSAGES_READ', payload: room.roomUuid });
			return;
		}
		const bottom =
			Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) === Math.round(e.target.clientHeight);
		if (bottom) {
			patchChatToRead(room.roomUuid);
			notificationsDispatch({ type: 'SET_ALL_MESSAGES_READ', payload: room.roomUuid });
		}
	};

	function handleChange() {
		if (message.current.value !== '') {
			setInputFilled(true);
		} else {
			setInputFilled(false);
		}
	}

	function prepMessages(room) {
		const { messages } = room;
		let newRoom = { roomUuid: room.roomUuid, messages: [] };
		for (let i = 0; i < messages.length; i++) {
			if (
				messages[i - 1] &&
				messages[i] &&
				areDifferentDays(messages[i - 1].sentDateTime, messages[i].sentDateTime)
			) {
				newRoom.messages.push({ type: 'separator', date: messages[i].sentDateTime });
			} else if (i === 0) {
				newRoom.messages.push({ type: 'separator', date: messages[i].sentDateTime });
			}
			newRoom.messages.push(room.messages[i]);
		}
		setRoom(newRoom);
	}

	function pushMessage() {
		const userUuid = splitUserId(userState.currUser.sub);
		const name = `${userState.currUser?.prefix ? `${userState.currUser?.prefix} ` : ''}${
			userState.currUser?.given_name
		} ${userState.currUser?.family_name}`;

		const newMessage = {
			messageText: message.current.value,
			roomUuid: room.roomUuid ?? undefined,
			orderUuid: order.orderUuid ?? undefined,
			messageSenderTeamUuid: teamState.currentTeam.teamUuid,
			messageSenderUserUuid: userUuid,
			buyerTeamUuid: order?.buyerTeamUuid || undefined,
			sellerTeamUuid: order?.sellerTeamUuid || undefined,
			buyerUserUuid: order?.buyerTeamUuid === teamState.currentTeam.teamUuid ? userUuid : order?.buyerUserUuid,
			sellerUserUuid: order?.sellerTeamUuid === teamState.currentTeam.teamUuid ? userUuid : order?.sellerUserUuid,
			buyerUserRef: order?.buyerTeamUuid === teamState.currentTeam.teamUuid ? userState.currUser.name : null,
			sellerUserRef: order?.sellerTeamUuid === teamState.currentTeam.teamUuid ? userState.currUser.name : null,
			type: 'order',
		};

		mapMessageFields({ message: newMessage, userUuid, name, teamUuid: teamState.currentTeam?.teamUuid, room });

		socket.emit('send-chat-message', newMessage);
		newMessage.sentDateTime = new Date();

		let updatedRoom = room;
		updatedRoom.messages.push(newMessage);
		if (!chatStarted) {
			setChatStarted(true);
		}
		setRoom((room) => ({
			...room,
			messages: updatedRoom.messages,
		}));
		divEnd.current.scrollIntoView({ behavior: 'smooth' });
		message.current.value = '';
		setInputFilled(false);
	}

	useEffect(() => {
		const getOrderRoom = async () => {
			try {
				Axios({
					url: `${config.apiv1}/chat/room.read/order/${order?.orderUuid}`,
					method: 'GET',
				})
					.catch((err) => {
						return err;
					})
					.then((res) => {
						if (res?.data?.data?.orderUuid) {
							if (res?.data?.data?.messages?.length === 0) {
								setRoom({ ...res?.data?.data });
								setChatStarted(false);
								setLoaded(true);
							} else {
								prepMessages(res.data.data);
							}
							setLoaded(true);
						} else {
							Axios({
								url: `${config.apiv1}/chat/room.create`,
								method: 'POST',
								data: {
									orderUuid: order.orderUuid,
									teamParticipants: [order.buyerTeamUuid, order.sellerTeamUuid],
									type: 'order',
								},
							})
								.catch((err) => {
									// if (err.response.status === 401) {
									// 	window.location.reload();
									// }
								})
								.then((res) => {
									setRoom({ ...res?.data?.data });
									setLoaded(true);
									setChatStarted(true);
								});
						}
					});
			} catch (err) {
				if (err.message === 'ROOM_NOT_FOUND') {
					setRoom({ uuid: order.orderUuid, messages: [] });
					setLoaded(true);
				}
				// if (err.response.status === 401) {
				// 	window.location.reload();
				// }
			}
		};
		getOrderRoom();
	}, [order]); // eslint-disable-next-line

	useEffect(() => {
		let mounted = true;
		if (loaded && socket !== null && mounted) {
			if (room && !socketJoined) {
				setSocketJoined(true);
				handleScroll();

				// Socket chat events
				socket.on('chat-message', (message) => {
					if (message.roomUuid === room.roomUuid) {
						setRoom((room) => ({
							...room,
							messages: [...room.messages, message],
						}));
						handleScroll();
						handleNewMessage();
					}
				});
			}
			divEnd.current.scrollIntoView({ block: 'end' });
		}
		return () => {
			socket.off('chat-message');
			mounted = false;
		};
	}, [loaded, socket, chatStarted]); // eslint-disable-next-line

	useEffect(() => {
		if (socket.disconnected) {
			socket.emit('join-room', room.roomUuid, userState.currUser.given_name);
		}
	}, [socket]);

	useEffect(() => {
		if (loaded && chatStarted) {
			divEnd.current.scrollIntoView({ block: 'end' });
			message.current.focus();
		}
	}, [room]); // eslint-disable-next-line

	return (
		<>
			<div className='chatContainer' id='chat'>
				<>
					<div className='chatContactHeader'>
						<Link
							onClick={() => rightSidebarDispatch({ type: 'SET_SIDEBAR_OPEN', payload: false })}
							to={`/suppliers/${
								teamState.currentTeam.teamUuid === order.sellerTeamUuid
									? order?.buyerTeamUuid
									: order?.sellerTeamUuid
							}?name=${
								teamState.currentTeam.teamUuid === order.sellerTeamUuid
									? order?.buyerTeamName
									: order?.sellerTeamName
							}`}>
							<h4 className='contactName'>
								{teamState.currentTeam.teamUuid === order.sellerTeamUuid
									? order.buyerTeamName
									: order.sellerTeamName}
							</h4>
						</Link>
					</div>
					<div
						className='chatBox'
						onScroll={(e) => {
							handleScroll(e);
						}}
						onClick={(e) => {
							handleScroll(e);
						}}
						ref={chatBox}>
						{loaded ? (
							<>
								{room.messages ? (
									room.messages.map((message, key) => {
										return (
											<React.Fragment key={key}>
												{message.type === 'separator' ? (
													<>
														<h4>{clarifyDate(message.date)}</h4>
													</>
												) : (
													<>
														<div
															className={
																message.messageSenderTeamUuid ===
																teamState.currentTeam.teamUuid
																	? 'chatMessageUser'
																	: 'chatMessage'
															}>
															<div className='messageTopCont'>
																{message.messageSenderTeamUuid !==
																	teamState.currentTeam.teamUuid && (
																	<p className='messageUserName'>
																		{message.messageSenderTeamUuid ===
																		order.buyerTeamUuid
																			? message.buyerUserRef
																			: message.sellerUserRef}
																	</p>
																)}
																<p className='chatTimestamp'>
																	{timestampConverter(
																		message.sentDateTime,
																		'hh:mm',
																		'Europe/London'
																	)}
																</p>
															</div>
															<p className='messageContent'>{message.messageText}</p>
														</div>
													</>
												)}
											</React.Fragment>
										);
									})
								) : (
									<></>
								)}
							</>
						) : (
							<>
								<Loading type='circle' />
							</>
						)}
						<div style={{ visibility: 'hidden' }} ref={(e) => (divEnd.current = e)} />
					</div>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							pushMessage();
							handleChange();
						}}
						className='submitCont'
						autoComplete='off'>
						<input
							placeholder='Message'
							onChange={() => {
								handleChange();
							}}
							ref={message}
							name='message'
							className='messageInput'
						/>
						<button disabled={!inputFilled} className='chatSubmitBtn'>
							<PaperPlaneIcon iconClass={inputFilled ? 'paperPlaneIcon' : 'paperPlaneIcon'} />
						</button>
					</form>
				</>
			</div>
		</>
	);

	async function patchChatToRead(roomUuid) {
		if (!roomUuid) {
			return false;
		}

		let res = await Axios({
			method: 'PATCH',
			url: `${config.apiv1}/chat/room.update/messages/${roomUuid}`,
		});
	}
}

import React from 'react';
import currencyOptions from '../Options/currencyOptions';
import companyTypeOptions from '../Options/companyTypeOptions';
import taxOptions from '../Options/taxOptions';
import langOptions from '../Options/langOptions';
import TextInput from '../Inputs/TextInput';
import FileInput from '../Inputs/FileInput';
import TextareaInput from '../Inputs/TextareaInput';
import SelectInput from '../Inputs/SelectInput';
import RadioInput from '../Inputs/RadioInput';
import CheckboxInput from '../Inputs/CheckboxInput';
import Button from '../Button';
import countryOptions from '../Options/countryOptions';
import { TrashIcon } from '../SVGIcons/SVGIcons';
import { makeFirstCharUpper } from '../../helpers';

export default function TeamForm(props) {
	const {
		scrollToRef,
		handleSubmit,
		errorMessage,
		formData,
		setFormData,
		handleFormData,
		handleFormSelectData,
		registeredAddress,
		handleRegisteredAddressInput,
		collectionAddress,
		handleCollectionAddressInput,
		formTitle,
		registering,
	} = props || {};

	const { pageEnd, pageStart, acceptCollectRef, isCompanyRef, isDeliveryAddressRef } = props.refs;

	function handleNewCustomReason() {
		setFormData((formData) => {
			let formReasons =
				formData.customHoldReasons && Object.keys(formData.customHoldReasons)?.length !== 0
					? formData.customHoldReasons
					: {};

			let parsedCustomHoldReason = formData.customHoldReason.replace(' ', '_');
			parsedCustomHoldReason = parsedCustomHoldReason.toLowerCase();

			return {
				...formData,
				customHoldReasons: {
					...formReasons,
					[parsedCustomHoldReason]: { text: formData.customHoldReason, value: parsedCustomHoldReason },
				},
				customHoldReason: '',
			};
		});
	}

	function handleRemoveCustomReason(customHoldReason) {
		setFormData((formData) => {
			let newFormData = { ...formData };
			delete newFormData.customHoldReasons[customHoldReason];
			return newFormData;
		});
	}

	return (
		<div className='mainFormCont'>
			<div className='pageEnd' ref={pageEnd}></div>
			<form onSubmit={handleSubmit}>
				<h2>{formTitle}</h2>
				<h3 ref={pageStart} className={`errorMsg ${errorMessage ? '' : 'hidden'}`}>
					{errorMessage}
				</h3>
				<div className='formSection'>
					<TextInput
						text={'Display name'}
						name={'teamName'}
						callback={handleFormData}
						defaultValue={formData.teamName}
					/>
					{!registering && (
						<TextInput
							text={'Abbreviation'}
							name={'teamInitials'}
							callback={handleFormData}
							defaultValue={formData.teamInitials?.toUpperCase()}
							maxLength={3}
						/>
					)}
					<FileInput
						text={'Upload logo'}
						name={'formProfileImg'}
						callback={handleFormData}
						btnText={'Choose image'}
					/>
					{formData.formProfileImg && (
						<div className='flexRow'>
							<p className='extraBold'>{formData.formProfileImg.name}</p>
							<Button
								size='sm'
								style='secondary'
								text='Remove'
								onClick={() => handleFormData(null, 'formProfileImg')}
							/>
						</div>
					)}
					{/* <TextInput
						text={'Slogan'}
						name={'companyDescriptionHeader'}
						callback={handleFormData}
						defaultValue={formData.companyDescriptionHeader}
					/> */}
					<TextareaInput
						text={'Tagline'}
						name={'companyDescription'}
						defaultValue={formData.companyDescription}
						callback={handleFormData}
					/>
					<SelectInput
						htmlOptions={taxOptions}
						callback={handleFormSelectData}
						value={formData.tax}
						name={'tax'}
						text={'Tax jurisdiction'}
					/>
					<SelectInput
						htmlOptions={langOptions}
						callback={handleFormSelectData}
						value={formData.spokenLanguage}
						name={'spokenLanguage'}
						text={'Language'}
					/>
					<TextInput
						text={'Phone number'}
						name={'phoneNumber'}
						callback={handleFormData}
						defaultValue={formData.phoneNumber}
						type='phone'
					/>
					<TextInput
						text={'Email'}
						name={'businessEmail'}
						callback={handleFormData}
						defaultValue={formData.businessEmail}
						type='email'
					/>
					<TextInput
						text={'Website address'}
						name={'websiteUrl'}
						callback={handleFormData}
						defaultValue={formData.websiteUrl}
						type='text'
					/>
					<TextInput
						text={'MHRA number'}
						name={'mhra_registration_number'}
						callback={handleFormData}
						defaultValue={formData.mhra_registration_number}
						type='text'
					/>
					<SelectInput
						htmlOptions={currencyOptions}
						callback={handleFormSelectData}
						value={formData.currency}
						name={'currency'}
						text={'Currency'}
					/>
				</div>
				<div className='formSection'>
					<h3>Company</h3>
					<CheckboxInput
						text={'Is the team pertaining to a company?'}
						checkboxText={'Yes'}
						name={'isCompany'}
						checked={formData.isCompany}
						callback={handleFormData}
						extraFunction={() => {
							handleRegisteredAddressInput();
							handleFormData(null, 'companyName');
							// handleFormData(null, 'companyType');
							handleFormData(null, 'companyCountry');
							handleFormData(null, 'companyNumber');
							scrollToRef(isCompanyRef);
						}}
					/>
					{formData.isCompany && (
						<>
							<TextInput
								text={'Company name'}
								name={'companyName'}
								callback={handleFormData}
								defaultValue={formData?.companyName}
								ref={isCompanyRef}
							/>
							{/* <SelectInput
								htmlOptions={companyTypeOptions}
								callback={handleFormSelectData}
								value={formData.companyType}
								name={'companyType'}
								text={'Company type'}
							/> */}
							<SelectInput
								htmlOptions={countryOptions}
								callback={handleFormSelectData}
								value={formData.companyCountry}
								name={'companyCountry'}
								text={'Country of registration'}
							/>
							<TextInput
								text={'Company number'}
								name={'companyNumber'}
								callback={handleFormData}
								defaultValue={formData?.companyNumber}
							/>
							{/* <TextInput
								text={'County'}
								name={'companyCounty'}
								callback={handleFormData}
								defaultValue={formData?.companyCounty}
							/> */}
							<h3>Registered Company Address</h3>
							<TextInput
								text={'Name:'}
								name={'name'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.name}
								required
							/>
							<TextInput
								text={'Address line 1:'}
								name={'addressLine1'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.addressLine1}
								required
							/>
							<TextInput
								text={'Address line 2 (optional)'}
								name={'addressLine2'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.addressLine2}
							/>
							<TextInput
								text={'Address line 3 (optional)'}
								name={'addressLine3'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.addressLine3}
							/>
							<TextInput
								text={'City'}
								name={'city'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.city}
								required
							/>
							<TextInput
								text={'Postcode'}
								name={'postalCode'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.postalCode}
								required
							/>
							<TextInput
								text={'County / state / province:'}
								name={'county'}
								callback={handleRegisteredAddressInput}
								defaultValue={registeredAddress?.county}
								required
							/>
							<SelectInput
								htmlOptions={countryOptions}
								callback={handleRegisteredAddressInput}
								value={registeredAddress?.country}
								text={'Country'}
								name={'country'}
								required
							/>
						</>
					)}
				</div>
				<div className='formSection'>
					<h3>Collection</h3>
					<RadioInput
						text={'Do you allow collection?'}
						name={'acceptCollect'}
						defaultValue={formData.acceptCollect ? true : false}
						callback={handleFormData}
						radios={[
							{
								value: true,
								text: 'Yes',
								extraFunction: () => {
									scrollToRef(acceptCollectRef);
								},
							},
							{
								value: false,
								text: 'No',
								extraFunction: () => {
									handleCollectionAddressInput();
								},
							},
						]}
					/>
					{formData.acceptCollect && (
						<>
							<TextInput
								text={'Collection point'}
								name={'collectionPoint'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.collectionPoint}
							/>
							<TextInput
								text={'Address line 1'}
								name={'addressLine1'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.addressLine1}
							/>
							<TextInput
								text={'Address line 2 (optional)'}
								name={'addressLine2'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.addressLine2}
							/>
							<TextInput
								text={'Address line 3 (optional)'}
								name={'addressLine3'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.addressLine3}
							/>
							<TextInput
								text={'City'}
								name={'city'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.city}
							/>
							<TextInput
								text={'Postcode'}
								name={'postalCode'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.postalCode}
							/>
							<TextInput
								text={'County / State / Province'}
								name={'county'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.county}
							/>
							<SelectInput
								htmlOptions={countryOptions}
								callback={(value, name) => {
									handleCollectionAddressInput(value?.value, name);
								}}
								value={collectionAddress?.country}
								name={'country'}
								text={'Country'}
								required
							/>
							<TextInput
								text={'Collection hours'}
								name={'collectionHours'}
								callback={handleCollectionAddressInput}
								defaultValue={collectionAddress?.collectionHours}
							/>
							<TextareaInput
								type='text'
								style='new'
								text='Instructions'
								name={'instructions'}
								defaultValue={collectionAddress?.instructions}
								callback={handleCollectionAddressInput}
								short
							/>
						</>
					)}
				</div>
				<div className='formSection'>
					<h3>Holds</h3>
					<>
						<TextInput
							type='text'
							text='Custom hold reason'
							name='customHoldReason'
							callback={handleFormData}
							tooltip=''
							placeholder=''
							value={formData?.customHoldReason}
							controlled
						/>
						<Button
							disabled={!formData?.customHoldReason}
							text='Add reason'
							type='button'
							style='secondary'
							onClick={handleNewCustomReason}
						/>
						{formData.customHoldReasons && Object.keys(formData.customHoldReasons)?.length !== 0 && (
							<h4 className='marginTop-10'>Below are your current custom reasons</h4>
						)}
						<div className='customHoldReasonList'>
							{formData.customHoldReasons &&
								Object.values(formData.customHoldReasons).map((reason, key) => (
									<React.Fragment key={key}>
										{reason ? (
											<div className='customHoldReason' key={key}>
												{reason.text}
												<TrashIcon
													iconClass='trashIcon'
													onClick={() => {
														handleRemoveCustomReason(reason.value);
													}}
												/>
											</div>
										) : (
											<></>
										)}
									</React.Fragment>
								))}
						</div>
					</>
				</div>
				<div className='formSection'>
					<h3>Invoicing</h3>
					<RadioInput
						text={'Order reference type'}
						name={'orderRefFormat'}
						defaultValue={formData?.orderRefFormat}
						callback={handleFormData}
						radios={[
							{
								value: 1,
								text: 'Team Initials - Invoice Count',
							},
							{
								value: 2,
								text: 'Team Initials - Invoice Count - Date',
							},
						]}
					/>
				</div>
				<CheckboxInput
					text={'Do you wish to make your Team public? (This can always be changed later)'}
					checkboxText={'Yes'}
					name={'isPublic'}
					checked={formData.isPublic}
					callback={handleFormData}
				/>
				<div className='submitBtnCont'>
					<input type='submit' className='submitBtn' value='Save' />
				</div>
			</form>
		</div>
	);
}

import React, { useState } from 'react';
import { SearchIcon } from '../SVGIcons/SVGIcons';
import useCompVis from '../../hooks/useCompVis';

export default function SearchInput(props) {
	const { callback, dentallyToken, handleOption, options, type, name, noFormCont, defaultValue } = props || {};

	const [dropOpen, setDropOpen] = useState(false);

	const { ref } = useCompVis(true, () => setDropOpen(false));

	return (
		<div className={!noFormCont ? 'formRowCenter' : 'thinCont'}>
			<div className='searchContainer' ref={ref}>
				<input
					className='searchBar'
					onFocus={() => setDropOpen(true)}
					placeholder='Search'
					onChange={(e) =>
						callback({ searchValue: e.target.value !== '' ? e.target.value : null, dentallyToken }, type)
					}
					id={name}
					defaultValue={defaultValue}
				/>
				<SearchIcon iconClass='inputIcon pointer' />
				{options && dropOpen && options?.length > 0 && (
					<div className='options'>
						{options?.map((option, key) => {
							return (
								<div
									className='option'
									key={key}
									onClick={(e) => {
										e.stopPropagation();
										handleOption(option);
										setDropOpen(false);
									}}>
									{type === 'dentallyPatients' && (
										<>
											{`${option.first_name ? ` ${option.first_name}` : ''}${
												option.last_name ? ` ${option.last_name}` : ''
											}${option.id ? ` #${option.id}` : ''}${
												option.date_of_birth ? ` ${option.date_of_birth}` : ''
											}`}
										</>
									)}
									{type === 'suppliers' && <>{option?.name}</>}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}

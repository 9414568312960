import React from 'react';
import TooltipIcon from '../TooltipIcon';

export default function Table({ tableRows, tableColumns }) {
	return (
		<div className='table-container'>
			<table className='custom-table'>
				<thead>
					<tr>
						<th></th>
						{tableColumns.map((col, index) => (
							<th key={index}>{col.label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{tableRows.map((row, rowIndex) => (
						<tr
							key={rowIndex}
							className={`${
								row.type === 'subheader'
									? 'subheaderRow'
									: row.type === 'clear'
									? 'clearRow'
									: 'defaultRow'
							}`}>
							<td className='rowLabel'>
								{row.label?.text}
								{row.label?.tooltip ? (
									<TooltipIcon tooltip={row.label?.tooltip} id={row.label?.text} />
								) : null}
							</td>
							{tableColumns.map((col, colIndex) => (
								<td className='rowData' key={colIndex}>
									{row.values && row.values[col.key] ? row.values[col.key] : <></>}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

import React from 'react';
import { checkStatusIfDisable, getCurrencySymbolFromTeam } from '../../../helpers';
import { PlusIcon } from '../../../components/SVGIcons/SVGIcons';
import { useNavigate } from 'react-router';

export default function OrderFinancial(props) {
	const {
		suggestedPriceStatus,
		handleFadeOutModal,
		handleNewOrderData,
		setShowPriceInput,
		showPriceInput,
		newOrderData,
		suggestPrice,
		patchOrder,
		isSeller,
		seller,
		order,
	} = props?.props || {};

	let navigate = useNavigate();
	return (
		<div className='gridItemOrder financial'>
			<div className='row highlight'>
				<h4 className='gridHeader'>Financial Overview</h4>
				<div className='gridIconCont'>
					{/* RE-ADD WHEN INVOICING IS BEING DEVELOPED */}
					{/* {isSeller && (
						<>
							{checkStatusIfDisable(order.orderProcessingStatus, 'editOrder') ? (
								<div className='gridIconRegular discIcon disabledSVG'>
									<PlusIcon />
								</div>
							) : (
								<div
									className='gridIconRegular discIcon'
									onClick={() => {
										navigate(`/invoice-create?orderUuid=${order.orderUuid}`);
									}}>
									<PlusIcon />
								</div>
							)}
						</>
					)} */}
				</div>
			</div>
			<div className='row highlight'>
				<h4 className='text'>Total</h4>
				{order?.totalPrice ? (
					<div className='data bold'>
						{`${Number(order?.totalPrice)?.toFixed(2)} ${getCurrencySymbolFromTeam(seller)}`}
						{!isSeller && !order?.totalPrice && `0.00 ${getCurrencySymbolFromTeam(seller)}`}
					</div>
				) : (
					<div className='data bold'>
						{!isSeller && !order?.totalPrice && `0.00 ${getCurrencySymbolFromTeam(seller)}`}
					</div>
				)}
				{isSeller &&
					!order?.totalPrice &&
					!showPriceInput &&
					suggestedPriceStatus !== 'accepted' &&
					suggestedPriceStatus !== 'pending' && (
						<>
							{!checkStatusIfDisable(order.orderProcessingStatus, 'setPrice') ? (
								<div className='flexCenterCol'>
									<button
										className='btn3-primary marginTop-10'
										onClick={() => {
											setShowPriceInput(true);
										}}>
										Set price
									</button>
								</div>
							) : (
								<div className='flexCenterCol'>
									<button className='btn3-primary marginTop-10 disabledDiv'>Set price</button>
								</div>
							)}
						</>
					)}
				{showPriceInput && order?.acceptPricing && (
					<div className='flexCenterCol'>
						<input
							className='textFieldInput'
							type='text'
							name='totalPrice'
							placeholder='0.00'
							controlled
							value={newOrderData.totalPrice}
							onChange={(e) => {
								if (e.target.value === ',' || e.target.value === 'e') {
									return;
								}
								handleNewOrderData(e.target.value, e.target.name);
							}}
						/>
						<button
							className='btn3-primary marginTop-10'
							disabled={newOrderData?.totalPrice !== null ? false : true}
							onClick={() => {
								patchOrder({ totalPrice: newOrderData?.totalPrice });
								setShowPriceInput(false);
								handleFadeOutModal({
									show: true,
									isFading: false,
									message: 'New price was set',
								});
							}}>
							Update price
						</button>
					</div>
				)}
				{showPriceInput && !order?.acceptPricing && (
					<div className='flexCenterCol'>
						<input
							className='orderInput'
							type='text'
							name='totalPrice'
							placeholder='0.00'
							controlled
							value={newOrderData.totalPrice}
							onChange={(e) => {
								if (e.target.value === ',' || e.target.value === 'e') {
									return;
								}
								handleNewOrderData(e.target.value, e.target.name);
							}}
						/>
						<button
							className='btn3-primary marginTop-10'
							onClick={() => {
								suggestPrice();
								setShowPriceInput(false);
								handleFadeOutModal({
									show: true,
									isFading: false,
									message: 'Price suggestion sent',
								});
							}}>
							Suggest price
						</button>
					</div>
				)}
			</div>
			<div className='row'>
				<p className='text'>Paid </p>
				<div className='data bold'>{order?.isPaid ? 'Yes' : 'No'}</div>
			</div>
			<div className='row'>
				<p className='text'>Accept Estimate </p>
				<div className='data bold'>{order?.acceptEstimate ? 'Automatic' : 'Manual'}</div>
			</div>
			<div className='row'>
				<p className='text'>Price change </p>
				<div className='data bold'>{order?.acceptPricing ? 'Automatic' : 'Manual'}</div>
			</div>
		</div>
	);
}

import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import AvatarImg from '../../../components/AvatarImg';
import TeamIcon from '../../../components/TeamIcon';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';

export default function ChatBoxHeader({ room }) {
	let navigate = useNavigate();
	const { teamState } = useContext(CurrentTeamContext);

	return (
		<div className='chatBoxHeader'>
			{room && (
				<>
					<div className='leftGroup'>
						{room.type === 'team' && room.teamParticipants?.length === 2 && (
							<TeamIcon
								team={room?.otherParty}
								tooltip={room?.otherParty?.teamInfo?.teamName}
								imgClass='defaultTeamImg'
								imgStyle={{ marginRight: '10px' }}
								style={{ marginRight: '10px' }}
							/>
						)}
						<h4 className='chatTitle'>{room?.chatTitle}</h4>
					</div>
					{room?.orderUuid && (
						<div className='rightGroup'>
							<Button text='Go to order' onClick={() => navigate(`/orders/${room.orderUuid}`)} />
							<Button
								text='Visit shop'
								onClick={() =>
									navigate(
										`/suppliers/${
											teamState.currentTeam.teamUuid === room.sellerTeamUuid
												? room?.buyerTeamUuid
												: room?.sellerTeamUuid
										}?name=${
											teamState.currentTeam.teamUuid === room.sellerTeamUuid
												? room?.buyerTeamName
												: room?.sellerTeamName
										}`
									)
								}
							/>
						</div>
					)}
				</>
			)}
			{/* Ask Tom about when we should display "Visit Shop" and what data we should base it on */}
		</div>
	);
}

import React, { useContext, useRef, useState } from 'react';
import labmanager from '../../assets/img/labManager-logo-green-005B00.svg';
import dentally from '../../assets/img/logo_dentally.svg';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config';
import Cookies from 'js-cookie';
import { AppIcon, GearIcon } from '../../components/SVGIcons/SVGIcons';
import FlexModal from '../../components/Modals/FlexModal';
import useModals from '../../hooks/useModals';
import TextInput from '../../components/Inputs/TextInput';
import { checkSufficientSubscriptionLevel, makeFirstCharUpper } from '../../helpers';
import ManageWrap from '../../components/Wrappers/ManageWrap';
import SubscriptionAdvertModal from '../../components/Modals/SubscriptionAdvertModal';
import { useModalPortal } from '../../contexts/ModalPortalContext';

export default function TeamApps() {
	let { teamState } = useContext(CurrentTeamContext);
	let { userState } = useContext(CurrentUserContext);
	const { openModalPortal, closeModalPortal } = useModalPortal();

	let navigate = useNavigate();

	let pageContainerRef = useRef();

	const [dentallyTEMPData, setDentallyTEMPData] = useState({});

	const { modalsState, handleModal } = useModals();

	const apps = [
		{
			appName: 'labmanager',
			logo: labmanager,
			url:
				`${config.pconHUB}/Identity/Account` +
				`/RegisterLink?returnUrl=${window.location.href}&email=${userState.currUser.email}` +
				`&sellerteamuuid=${teamState.currentTeam.teamUuid}`,
		},
		{
			appName: 'dentally',
			logo: dentally,
			url:
				`${config.dentallyAPI}/oauth/authorize?` +
				`redirect_uri=${config.host}/redirects/dentally&scope=appointment patient:read ` +
				`patient:update user:read&response_type=code`,
		},
	];

	const handleOpenModalPortal = () => {
		openModalPortal(
			<SubscriptionAdvertModal
				subscriptionLevelName={'Business'}
				featureName={'apps'}
				close={closeModalPortal}
			/>,
			pageContainerRef.current
		);
	};

	function integrate(url, appName) {
		if (!url) {
			return;
		}

		if (!checkSufficientSubscriptionLevel(teamState?.currentTeam?.subscription, 'apps', 1)) {
			handleOpenModalPortal();
			return;
		}

		if (appName === 'dentally') {
			handleModal('integrationModal', { open: true, url: url });
			return;
		}

		const lastState = uuidv4();
		Cookies.set(`lastState`, lastState, { expires: 0.2 });

		//!TEMP
		Cookies.set(`savedSecret`, dentallyTEMPData.clientSecret, { expires: 1 });
		Cookies.set(`savedID`, dentallyTEMPData.clientID, { expires: 1 });
		//!TEMP

		let newUrl = new URL(url);
		newUrl.searchParams.set('state', lastState);

		//!TEMP
		if (dentallyTEMPData.clientID) {
			newUrl.searchParams.set('client_id', dentallyTEMPData.clientID);
		}
		//!TEMP

		window.location.href = newUrl.href;
	}

	return (
		<>
			{modalsState.integrationModal?.open && (
				<FlexModal
					setShow={handleModal}
					modalName={'integrationModal'}
					zIndex={99}
					btn1={dentallyTEMPData.clientID && dentallyTEMPData.clientSecret ? 'Integrate' : null}
					closeBtn={true}
					callback={() => {
						integrate(modalsState.integrationModal?.url);
					}}
					body={
						<div>
							<h3>To integrate dentally, please follow the instructions below.</h3>
							<h4>
								First you need to go to your Dentally account at{' '}
								<span
									className='underline'
									onClick={() =>
										window.open('https://dentally.co/settings/developer/apps', '_blank')
									}>
									https://dentally.co/settings/developer/apps
								</span>
								, then click "New application".
							</h4>
							<h4>
								In the form, fill in the name field to be "Procuur", the url field to be
								"https://procuur.com" and the redirect URI field to be
								"https://procuur.com/redirects/dentally". Leave all other fields blank
							</h4>
							<h4>
								When presented with a success message, fill in the Application Id and Secret you are
								given in the fields below.
							</h4>
							<TextInput
								text='Application Id'
								value={dentallyTEMPData?.clientID}
								callback={(e) =>
									setDentallyTEMPData((data) => ({ ...data, clientID: e?.replace(/ /g, '') }))
								}
								controlled
							/>
							<TextInput
								text='Secret'
								value={dentallyTEMPData?.clientSecret}
								callback={(e) =>
									setDentallyTEMPData((data) => ({ ...data, clientSecret: e?.replace(/ /g, '') }))
								}
								controlled
							/>
						</div>
					}
				/>
			)}
			<ManageWrap
				backColor='white'
				style={window.innerWidth > 768 ? { padding: '70px' } : null}
				ref={pageContainerRef}>
				<div className='teamAppsCont'>
					<div className='appsHeader'>
						<h2>
							<AppIcon iconClass='appIcon' />
							Apps
						</h2>
						<p>Connect Procuur to your favourite software and push your work to where you need it.</p>
					</div>
					<div className='appsRow'>
						<div className='leftDesc'>
							<h4 className='smallHeader'>Installed</h4>
							<p>These are your currently installed apps.</p>
						</div>
						<div className='rowItems'>
							{apps.map((app, key) => {
								let integrations = teamState.currentTeam?.integrations || {};
								if (integrations[app.appName]) {
									return (
										<div key={key} className='appItem'>
											<img className='appLogo' src={app.logo} alt='' />
											<button
												className='btn3-secondary hug'
												onClick={() => {
													navigate(`/manage/apps/settings?appName=${app.appName}`);
												}}>
												<GearIcon iconClass='btnIconLeft' /> Manage
											</button>
										</div>
									);
								}
							})}
						</div>
					</div>
					<div className='appsRow'>
						<div className='leftDesc'>
							<h4 className='smallHeader'>Recommended</h4>
						</div>
						<div className='rowItems'>
							{apps.map((app, key) => {
								let integrations = teamState.currentTeam?.integrations || {};
								if (!integrations[app.appName]) {
									return (
										<div key={key} className='appItem'>
											<img
												className='appLogo'
												src={app.logo}
												alt={`${makeFirstCharUpper(app.appName)} logo`}
											/>
											<button
												className='btn3-secondary hug'
												onClick={() => {
													integrate(app.url, app.appName);
												}}>
												Install
											</button>
										</div>
									);
								}
							})}
						</div>
					</div>
				</div>
			</ManageWrap>
		</>
	);
}

import React, { useEffect, useState } from 'react';
import OrderItemNewForm from '../Forms/OrderItemNewForm';
import Loading from '../Loading/Loading';
import Overlay from '../Overlay';
import Button from '../Button';
import useFetch from '../../hooks/useFetch';
import config from '../../config';

export default function OrderItemNewModal(props) {
	const {
		handleModal,
		closeModal,
		updateLoading,
		setUpdateLoading,
		setShowOverlay,
		packages,
		handleData,
		postItem,
		formData,
		handleFormSelectData,
		missingOptions,
		zIndex,
	} = props || {};

	const { fetch } = useFetch();

	const [filteredPackages, setFilteredPackages] = useState({});

	const filterPackages = async (packages, formData, maxStatus = 3) => {
		if (formData?.packageUuid && packages[formData?.packageUuid]) {
			packages[formData?.packageUuid] = await fetch(
				`${config.apiv1}/package/package.read/${formData?.packageUuid}`
			);
		}

		let packs = Object.fromEntries(
			Object.entries(packages).filter(
				([_, pack]) => pack?.last?.status < maxStatus || pack?.packageUuid === formData?.packageUuid
			)
		);
		setFilteredPackages(() => ({ ...packs }));
	};

	useEffect(() => {
		filterPackages(packages, formData, 3);
	}, [packages, formData]);

	return (
		<>
			<div className='flexModal' style={{ zIndex: zIndex }}>
				{!updateLoading ? (
					<div className='mainFormCont'>
						<OrderItemNewForm
							packages={filteredPackages}
							handleData={handleData}
							formData={formData}
							handleModal={handleModal}
							handleFormSelectData={handleFormSelectData}
							missingOptions={missingOptions}
						/>
						<div className='btnCont modal reverse'>
							<Button
								text='Save'
								style='primary'
								type='lg'
								onClick={() => {
									setUpdateLoading(true);
									postItem();
								}}
							/>
							<Button
								text='Close'
								style='secondary'
								type='lg'
								onClick={() => {
									closeModal('orderItemNewModal', false);
									setShowOverlay(false);
								}}
							/>
						</div>
					</div>
				) : (
					<>
						<Loading type='circle' />
					</>
				)}
			</div>
			<Overlay loadOverlay={true} showOverlay={true} zIndex={zIndex} />
		</>
	);
}
